import { Avatar, Typography, ListItem, ListItemAvatar, 
  ListItemText, IconButton } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities';
import * as Utils from '../util/Utils'

const handleStyle = {
  // display: 'flex',
  // width: '12px',
  // padding: '15px',
  // alignItems: 'center',
  // justifyContent: 'center',
  // flex: '0 0 auto',
  // touch - action: none,
  cursor: 'var(--cursor, pointer)',
  // border - radius: 5px,
  border: 'none',
  outline: 'none',
  // appearance: none,
  backgroundColor: 'transparent',
  // -webkit - tap - highlight - color: transparent,
}


export default function DragableScan(props: {
  scan: any,
  index: number,
  selectedIndex: number[],
  toggleSelection: (e: any, index: number) => void,
  selectIndex: (index: number) => void
}) {

  const {attributes, listeners, setNodeRef, 
    transform, transition} = useSortable({
      id: props.scan['id'],
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  };

  return (
    <ListItem
      ref={setNodeRef}
      key={props.scan.id}
      id={props.scan.id}
      style={style}
      onClick={e => props.selectIndex(props.index)}
      sx={{
        padding: '10px',
        borderBottom: '1px solid #eeeeee',
        bgcolor: (props.selectedIndex.indexOf(props.index) >= 0) ? '#e0f7fa' : '',
        "&:hover": {
          cursor: 'pointer',
          bgcolor: (props.selectedIndex.indexOf(props.index) >= 0) ? '#e0f7fa' : '#f5f5f5',
        }
      }}
      secondaryAction={
        <button style={handleStyle} 
          {...attributes} {...listeners} ref={setNodeRef}>
          <DragIndicatorIcon fontSize='small' color='inherit'
            sx={{"&:hover": {cursor: 'grab'}}}
          />
        </button>
      }
    >
      <ListItemAvatar>
        <Avatar
          onClick={e => props.toggleSelection(e, props.index)}
          sx={{
            fontSize: '80%',
            marginRight: '50px',
            width: 40, height: 40,
            bgcolor: (props.selectedIndex.indexOf(props.index) >= 0) ? '#1976d2' : blueGrey[100],
            "&:hover": {
              cursor: 'pointer',
              bgcolor: (props.selectedIndex.indexOf(props.index) >= 0) ? '#1976d2' : blueGrey[500],
            }
          }}
        >
          {props.index}
        </Avatar>
      </ListItemAvatar>

      {
        props.scan['message']
          ?
          <ListItemText primary={props.scan['name']}
            secondary={
              <Typography color={'red'}>
                {props.scan['message']}
              </Typography>
            }
          />
          :
          <ListItemText 
            primary={
              <div style={{display: 'flex'}}>
                <Typography variant="body1">
                  {props.scan['name']}
                </Typography>
                <Typography sx={{marginLeft: '5px'}}
                  variant="caption" color={blueGrey[500]}>
                  {(props.scan || {})['isCalibrator'] ? 'C' : 'T'}
                </Typography>
              </div>
            }
            secondary={
              <Typography variant="body2">
                {props.scan['ra']} {props.scan['dec']} {props.scan['coord_sys']}
                {' '} {Utils.getTargetVelocityString(props.scan)}
              </Typography>
            }
          />
      }
    </ListItem>
  );
}

