// speed of light km/s
export const SPEED_OF_LIGHT = 299792458/1000;
export const PROJECT_FILENAME = 'project.json';

export const ARRAY_HELP_URL = 'https://www.narrabri.atnf.csiro.au/operations/array_configurations/configurations.html';

export const SUB_BANDS_PER_IF = 15;
export const NUMBER_OF_IF = 2;

// MHz
export const IF_BAND_WIDTH = 1.92 * 1000;

// Max Band separation 
export const MAX_BAND_SPARATION = 6 * 1000;

export const CABB_BAND_WIDTH = 8 * 1000;

// for 7mm receiver, centre freq of CABB band cannot be
// bettwe 38 and 44 GHz
export const CABB_EXCLUDEZONE_7mm = [38*1000, 44*1000];  

export const COORDINATE_SYSTEMS = ['J2000', 'B1950', 'AzEl', 'Galactic'];
export const COORDINATE_SYSTEM_FIELDS: any = {
  'J2000': { ra: 'RA', ra_format: 'hms', dec: 'Dec', dec_format: 'dms'},
  'B1950': { ra: 'RA', ra_format: 'hms', dec: 'Dec', dec_format: 'dms' },
  'AzEl': { ra: 'Az', ra_format: 'dms', dec: 'El', dec_format: 'dms' },
  'Galactic': { ra: 'Long', ra_format: 'deg', dec: 'Lat', dec_format: 'deg' },
}

// minimun width in MHz a freq line is from the edge of the band
export const MIN_ZOOM_BANDWIDTH_GAP = 5

export const ReceiverRange = [
  { name: '16cm', start: 1100, end: 3100 },
  { name: '4cm', start: 3900, end: 11000 },
  { name: '15mm', start: 16000, end: 25000 },
  { name: '7mm', start: 30000, end: 50000 },
  { name: '3mm', start: 83000, end: 105000 }
];

export const SCAN_TYPES = ['Normal', 'Dwell', 'Mosaic', 'Point',
  'Paddle', 'Closefile', 'OTFMos', 'Focus'];

export const POINTINGS = ['Global', 'Offset', 'Offpnt', 'Update']


// google 20c colors
export const COLOR_LIST = ["#3366cc", "#dc3912", "#ff9900", "#109618",
  "#990099", "#0099c6", "#dd4477", "#66aa00",
  "#b82e2e", "#316395", "#994499", "#22aa99",
  "#aaaa11", "#6633cc", "#e67300", "#8b0707",
  "#651067", "#329262", "#5574a6", "#3b3eac"];

export const ARRAY_CONFIGURATIONS = [
  {
    name: '6A',
    stations: ['W4', 'W45','W102', 'W173', 'W195', 'W392'],
    note: ''
  },
  {
    name: '6B',
    stations: ['W2', 'W64', 'W147', 'W182', 'W196', 'W392'],
    note: ''
  },
  {
    name: '6C', 
    stations: ['W0  ', 'W10', 'W113', 'W140', 'W182', 'W392'],
    note: ''
  },
  {
    name: '6D', 
    stations: ['W8  ', 'W32', 'W84', 'W168', 'W173', 'W392'], 
    note: ''
  },
  {
    name: '1.5A', 
    stations: ['W100', 'W110', 'W147', 'W168', 'W196', 'W392'], 
    note: ''
  },
  {
    name: '1.5B', 
    stations: ['W111', 'W113', 'W163', 'W182', 'W195', 'W392'], 
    note: ''
  },
  {
    name: '1.5C', 
    stations: ['W98 ', 'W128', 'W173', 'W190', 'W195', 'W392'], 
    note: ''
  },
  {
    name: '1.5D', 
    stations: ['W102', 'W109', 'W140', 'W182', 'W196', 'W392'], 
    note: ''
  },
  {
    name: '750A', 
    stations: ['W147', 'W163', 'W172', 'W190', 'W195', 'W392'], 
    note: ''
  },
  {
    name: '750B', 
    stations: ['W98 ', 'W109', 'W113', 'W140', 'W148', 'W392'], 
    note: ''
  },
  {
    name: '750C', 
    stations: ['W64 ', 'W84', 'W100', 'W110', 'W113', 'W392'], 
    note: ''
  },
  {
    name: '750D', 
    stations: ['W100', 'W102', 'W128', 'W140', 'W147', 'W392'], 
    note: ''
  },
  { 
    name: 'EW367', 
    stations: ['W104', 'W110', 'W113', 'W124', 'W128', 'W392'], 
    note: '' 
  },
  { 
    name: 'EW352', 
    stations: ['W102', 'W104', 'W109', 'W112', 'W125', 'W392'], 
    note: '' 
  },
  {
    name: 'H214', 
    stations: ['W98', 'W104', 'W113', 'N5', 'N14', 'W392'], 
    note: 'also H214B, H214C, H214D'
  },
  {
    name: 'H168', 
    stations: ['W100', 'W104', 'W111', 'N7', 'N11', 'W392'], 
    note: 'also H168B, H168C, H168D'
  },
  {
    name: 'H75', 
    station: ['W104', 'W106', 'W109', 'N2', 'N5 ', 'W392'], 
    note: 'also H75B, H75C'
  },
  {
    name: 'EW214', 
    stations: ['W98', 'W102', 'W104', 'W109', 'W112', 'W392'], 
    note:'not routinely scheduled'
  },
  {
    name: 'NS214', 
    stations: ['W106', 'N2', 'N7', 'N11', 'N14', 'W392'], 
    note:'not routinely scheduled'
  },
  {
    name: '122C', 
    stations: ['W98', 'W100', 'W102', 'W104', 'W106', 'W392'], 
    note: 'not routinely scheduled'
  }
];

export const SCAN_INTENTS = {
  normal: [
    { 
      name: 'CALIBRATE_AMPLI', 
      note: 'scan to calibrate how the amplitudes have changed' 
    },
    { 
      name: 'CALIBRATE_BANDPASS', 
      note: 'scan to take data that will later be used for bandpass calibration' 
    },
    { 
      name: 'CALIBRATE_DELAY', 
      note: 'likely will always be specified along with CALIBRATE_BANDPASS' 
    },
    { 
      name: 'CALIBRATE_FLUX', 
      note: 'scan to take data that will later be used for flux density calibration' 
    },
    { 
      name: 'CALIBRATE_PHASE', 
      note: 'scan to calibrate how the phases have changed; will almost always be specified along with CALIBRATE_AMPLI' 
    },
    { 
      name: 'CALIBRATE_POINTING', 
      note: 'scan to determine local pointing offsets(the equivalent of the "Point" scan type now)' 
    },
    { 
      name: 'CALIBRATE_POLARIZATION', 
      note: 'scan to take data that will later be used for polarization calibration' 
    },
    { 
      name: 'OBSERVE_TARGET', 
      note: 'take data on a scientifically- interesting source' 
    },
    { 
      name: 'CALIBRATE_POL_LEAKAGE', 
      note: 'scan to take data that will later be used for polarization leakage calibration' 
    },
    { 
      name: 'CALIBRATE_POL_ANGLE', 
      note: 'scan to take data that will later be used for polarization angle calibration' 
    },
    { 
      name: 'OBSERVE_CHECK_SOURCE', 
      note: 'take data on a source for quality assurance purposes' 
    },
  ],

  advanced: [
    { name: 'CALIBRATE_FOCUS'},
    { name: 'CALIBRATE_FOCUS_X'},
    { name: 'CALIBRATE_FOCUS_Y' },
    { name: 'CALIBRATE_WVR' },
    { name: 'DO_SKYDIP' },
    { name: 'MAP_ANTENNA_SURFACE' },
    { name: 'MAP_PRIMARY_BEAM' },
    { name: 'TEST' },
    { name: 'UNSPECIFIED' },
    { name: 'CALIBRATE_ANTENNA_POSITION' },
    { name: 'CALIBRATE_ANTENNA_PHASE' },
    { name: 'SYSTEM_CONFIGURATION' },
    { name: 'MEASURE_RFI' },
    { name: 'CALIBRATE_ANTENNA_POINTING_MODEL' }
  ],

  other: [
    { name: 'CALIBRATE_SIDEBAND_RATIO'}, 
    { name: 'CALIBRATE_APPPHASE_ACTIVE' },
    { name: 'CALIBRATE_APPPHASE_PASSIVE' },
    { name: 'CALIBRATE_DIFFGAIN ' },
  ] 
}

export const SPECTRAL_LINE_FREQUENCIES = [
  { freq: "1420.405752", name: "HI neutral hydrogen" },
  { freq: "1612.231", name: "OH hydroxyl radical" },
  { freq: "1665.402", name: "OH hydroxyl radical" },
  { freq: "1667.359", name: "OH hydroxyl radical" },
  { freq: "1720.530", name: "OH hydroxyl radical" },
  { freq: "4829.660", name: "H2CO ortho-formaldehyde" },
  { freq: "6668.518", name: "CH3OH methanol" },
  { freq: "9098.332", name: "HC3N cyanoacetylene" },
  { freq: "12178.593", name: "CH30H methanol" },
  { freq: "14488.490", name: "H2CO ortho-formaldehyde" },
  { freq: "18343.145", name: "C3H2 ortho-cyclopropenylidene" },
  { freq: "22235.07985", name: "H20 ortho-water" },
  { freq: "23694.506", name: "NH3 para-ammonia" },
  { freq: "23722.634", name: "NH3 para-ammonia" },
  { freq: "23870.130", name: "NH3 ortho-ammonnia" },
  { freq: "42820.570", name: "SiO silicon monoxide" },
  { freq: "43122.0900", name: "SiO silicon monoxide" },
  { freq: "43423.8530", name: "SiO silicon monoxide" },
  { freq: "48990.9640", name: "CS carbon monosulfide" },
  { freq: "72039.3310", name: "DCO+ deuterated formylium" },
  { freq: "85640.4560", name: "SiO silicon monoxide" },
  { freq: "86243.4420", name: "SiO silicon monoxide" },
  { freq: "86754.2940", name: "H13CO+ formylium" },
  { freq: "86846.9980", name: "SiO silicon monoxide" },
  { freq: "88631.8470", name: "HCN hydrogen cyanide" },
  { freq: "89188.5180", name: "HCO+ formylium" },
  { freq: "90663.5740", name: "HNC hydrogen isocyanide" },
  { freq: "93173.8090", name: "N2H+ diazenylium" },
  { freq: "97980.9680", name: "CS carbon monosulfide" },
  { freq: "109782.1820", name: "C18O carbon monoxide" },
  { freq: "110201.3700", name: "13CO carbon monoxide" },
  { freq: "115271.2030", name: "J=1-0 CO carbon monoxide" },
  { freq: "230538.0000", name: "J=2-1 CO carbon monoxide" },
  { freq: "345795.9899", name: "J=3-2 CO carbon monoxide" },
  { freq: "461040.7682", name: "J=4-3 CO carbon monoxide" },
  { freq: "576267.9305", name: "J=5-4 CO carbon monoxide" },
  { freq: "691473.0763", name: "J=6-5 CO carbon monoxide" },
  { freq: "806651.8060", name: "J=7-6 CO carbon monoxide" },
  { freq: "921799.7000", name: "J=8-7 CO carbon monoxide" }
];
