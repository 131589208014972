import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined'; 
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined'; 
import { FormControl, FormControlLabel, Button,
  Radio, RadioGroup, Tooltip, Snackbar, Alert, IconButton, Menu, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ProjectContext } from './ProjectContext';
import * as Utils from '../util/Utils'

import './TargetCatalogueView.css';
import NewTargetDialog from '../components/NewTargetDialog';
import EditTargetDialog from '../components/EditTargetDialog';
import * as Planets from '../astro/Planets';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function TargetCatalogueView() {
  const queryParams = new URLSearchParams(useLocation().search);
  const projectContext = React.useContext(ProjectContext);
  // merge or replace
  const [addCatalogueAction, setAddCatalogueAction] = React.useState('merge');
  const [openTargetDialog, setOpenTargetDialog] = React.useState(false);
  const [settingAnchorEl, setSettingAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openSnack, setOpenSnack] = React.useState(false);

  const [openEditorDialog, setOpenEditorDialog] = React.useState(false);

  const { getRootProps, getInputProps } = useDropzone
  ({maxFiles: 1, 
    multiple: false,
    accept: { 'text/csv': ['.csv']},
    onDrop: acceptedFiles => {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e && e.target) {
          const text = e.target.result as string;
          let targets: any[] = getTargetFileContent(text);
          const content: string[] =
            targets.map((target: any) => (
              Utils.targetToString(target)
            ));
          const targetFile = content.join('\n');
          projectContext.setTargetFile(targetFile);
        }
      }
      reader.readAsText(acceptedFiles[0]);
    }
  });

  const saveTargetCatelogue = () => {
    projectContext.saveTargetFile(projectContext.targetFile);
  }

  const updateTargetCatalogue = (targets: any[]) => {
    const content: string[] = 
      targets.map((target: any) => (
        Utils.targetToString(target)
      ));

    const targetFile = content.join('\n');
    projectContext.setTargetFile(targetFile);
    projectContext.saveTargetFile(targetFile);
  }

  const getTargetFileContent = (content: string) => {
    if (addCatalogueAction === 'merge') {
      const oldContent = projectContext.targetFile;
      // new targets are added
      // targets which have changed, take the new version
      // comments can't be preserved, will be removed
      const newTargets = Utils.getTargets(content);
      const oldTargets = Utils.getTargets(oldContent);

      if (!oldTargets || oldTargets.length === 0) {
        return Utils.getTargets(content);
      }

      for (let target of oldTargets) {
        // add the target to newTargets if it doesn't exist in newTargets
        let targetExist = false;
        for (let newTarget of newTargets) {
          if (target.name === newTarget.name) {
            targetExist = true;
            break;
          }
        }

        if (!targetExist) {
          newTargets.push(target);
        }
      }
      return newTargets;
    } else {
      // replace
      return Utils.getTargets(content);
    }
  }

  const addNewTarget = (newTarget: any) => {
    let content = projectContext.targetFile;
    const targets = Utils.getTargets(content);

    let foundTarget = false;
    let different = false;
    for (let target of targets) {
      if (target['name'] === newTarget['name']) {
        foundTarget = true;
        if (target['ra'] !== newTarget['ra']) {
          different = true
          break;
        }
        if (target['dec'] !== newTarget['dec']) {
          different = true
          break;
        }
        if (target['coord_sys'] !== newTarget['coord_sys']) {
          different = true
          break;
        }
      }
    }

    if (foundTarget && different) {
      setOpenSnack(true);
      return;
    }
    
    setOpenTargetDialog(false);

    // add source to catalogue if it's not in there
    if (foundTarget)
      return;

    content += '\n' + Utils.targetToString(newTarget);
    projectContext.setTargetFile(content);
    projectContext.saveTargetFile(content);
  }

  const openNewTargetMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenTargetDialog(true);
  };

  const closeNewTargetMenu = () => {
    setOpenTargetDialog(false);
  };

  const openSettingMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSettingAnchorEl(event.currentTarget);
  };

  const closeSettingMenu = () => {
    setSettingAnchorEl(null);
  };

  const closeEditorDialog = () => {
    setOpenEditorDialog(false);
  }

  return (
    <Stack spacing={2} height={'calc(100vh - 90px)'}
      sx={{ padding: 1, flexDirection: 'column' }}>
      
      <EditTargetDialog 
        open = {openEditorDialog}
        targets={projectContext.targetFile}
        handleCancel={closeEditorDialog}
        handleSave = {updateTargetCatalogue}
      />

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={e => setOpenSnack(false)}
      >
        <Alert severity="error">
          A target with same name but different values exists in catalogue.
        </Alert>
      </Snackbar>

      <Stack direction={'row'} alignItems={'center'} 
        height={'40px'} justifyContent={'space-between'}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography key="project" variant="subtitle1" color="primary">
            {queryParams.get('project')}
          </Typography>

          <Typography key="project" variant="h6" color="primary">
            {'Target Catalogue'}
          </Typography>
        </Breadcrumbs>

        <Tooltip title="Download sample catelogue">
          <a href='target_catelogue_sample.csv' download>
            <Button variant="text" startIcon={<SystemUpdateAltOutlinedIcon />}>
            Download sample catelogue
            </Button>
          </a>
        </Tooltip>

        <Stack spacing={1} direction={'row'}>
          <Button variant="text"
            onClick={openNewTargetMenu}
            startIcon={<ControlPointIcon />}>
            Add
          </Button>

          <NewTargetDialog 
            addTarget={addNewTarget}
            open={openTargetDialog}
            handleClose={closeNewTargetMenu}
            message=''
          />

          <Button variant="text"
            onClick={e => setOpenEditorDialog(true)}
            startIcon={<EditNoteOutlinedIcon />}>
            Edit
          </Button>

          <Button variant="text"
            onClick={saveTargetCatelogue}
            startIcon={<SaveIcon />}>
            Save
          </Button>

          <IconButton aria-label="save" color='primary'
            onClick={openSettingMenu}>
            {
              Boolean(settingAnchorEl)
              ?
              <KeyboardDoubleArrowUpOutlinedIcon/>
              :
              <KeyboardDoubleArrowDownOutlinedIcon />
            }
          </IconButton>

          <Menu
            PaperProps={{ sx: { width: '250px', padding: '10px' } }}
            id="menu-appbar"
            anchorEl={settingAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: -45,
              horizontal: 'right',
            }}
            open={Boolean(settingAnchorEl)}
            onClose={closeSettingMenu}
          >
            <Typography>When loading catalogue file</Typography>
            <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
              <RadioGroup
                name="additional-catalogue"
                value={addCatalogueAction}
                onChange={e => setAddCatalogueAction(e.target.value)}
              >
                <FormControlLabel value="replace" control={<Radio />} label="Replace" />
                <FormControlLabel value="merge" control={<Radio />} label="Merge" />
              </RadioGroup>
            </FormControl>
          </Menu>
        </Stack>
      </Stack>

      <Stack className='load-file-content' overflow={'scroll'} width={'100%'} >
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Stack sx={{opacity: '0.6'}} style={{ padding: '10px', margin: 0}}>
              
            <TableContainer component={Paper} sx={{ width: '100%'}}>
              <Table aria-label="simple table">
                <TableHead sx={{ backgroundColor: '#cfd8dc' }}>
                  <TableRow>
                    <TableCell width={'20%'} align="center">Name</TableCell>
                    <TableCell width={'20%'} align="center">RA</TableCell>
                    <TableCell width={'20%'} align="center">DEC</TableCell>
                    <TableCell width={'20%'} align="center">Coordinate System</TableCell>
                    <TableCell width={'20%'} align="center">Velocity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Utils.getTargets(projectContext.targetFile).map((target) => (
                    <StyledTableRow key={target['name']}>
                      <TableCell align="center">
                        {target['name']}
                      </TableCell>
                      <TableCell align="center">
                        {target['ra']}
                      </TableCell>
                      <TableCell align="center">
                        {target['dec']}
                      </TableCell>
                      <TableCell align="center">
                        {target['coord_sys']}
                      </TableCell>
                      <TableCell align="center">
                        {Utils.getTargetVelocityString(target)}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <Typography className='hide' sx={{position: 'fixed'}} width={'100%'} 
            textAlign='center'>
            Drag 'n' drop ONE csv file here, or click to select a file
          </Typography>
        </div>
      </Stack>
    </Stack>
  );
}
