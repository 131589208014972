import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, 
  Snackbar, Alert, TextField, Typography, Paper, FormControl, Box,
  InputLabel, MenuItem, FormControlLabel, RadioGroup, Radio, Chip,
  TableContainer, Table, TableHead, TableRow, TableBody, Checkbox} 
  from "@mui/material";

import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { Stack } from "@mui/system";
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import * as ATCAConstants from '../util/ATCAConstants'
import * as Utils from '../util/Utils'
import { post } from 'aws-amplify/api';

import { ProjectContext } from '../schedule/ProjectContext';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    borderBottom: 'none',
    padding: '5px 10px 0 10px'
  },
}));

const StyledTextTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    borderBottomColor: '#eeeeee',
    padding: '10px 0 10px 0'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ITEM_HEIGHT = 35;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

export default function VeloDialog(
  props: {
    open: boolean,
    utcDate: Date,
    zoomBand: any,
    handleClose: () => void,
    corrConfig: any,
    updateZoomBand: (zoomBand: any) => void
  }
) {
  const [snackInfo, setSnackInfo] = React.useState<any>({
    open: false,
    message: '',
    severity: 'info'
  });

  const projectContext = React.useContext(ProjectContext);
  const [selectedTargets, setSelectedTargets] = React.useState<any[]>([]);
  const [targets, setTargets] = React.useState<any[]>([]);
  const [frequency, setFrequency] = React.useState('');
  const [freqType, setFreqType] = React.useState<string>('line');
  const [selectedSpectralLine, setSelectedSpectralLine] = React.useState<any>({});
  const [recommendedBand, setRecommendedBand] = React.useState<any>({});
  const [disableCalculate, setDisableCalculate] = React.useState(true);
  const [calculatedZoomValues, setCalculatedZoomValues] = React.useState<any>({});
 
  const [targetMatrix, setTargetMatrix] = React.useState<any[]>([]);

  React.useEffect(() => {
    const targetList = Utils.getTargets(projectContext.targetFile);
    setTargets(targetList);
  }, [projectContext.targetFile]);

  React.useEffect(() => {
    if (props.open) {
      setTargetMatrix([]);
      if (props.zoomBand) {
        const newZoomBand = JSON.parse(JSON.stringify(props.zoomBand));
        setRecommendedBand(newZoomBand);
        calculateZoomValues(newZoomBand);
        // set freq/freqtype/spectral_line
        if (Number(newZoomBand['rest_frequency']) > 0) {
          const lines = ATCAConstants.SPECTRAL_LINE_FREQUENCIES.filter(
            (l) => (Number(l['freq']).toFixed(2) === Number(newZoomBand['rest_frequency']).toFixed(2)
            ));
          if (lines && lines.length > 0) {
            setFreqType('line');
            setSelectedSpectralLine(lines[0]);
          } else {
            setFreqType('rest');
            setFrequency(newZoomBand['rest_frequency']);
            setSelectedSpectralLine(null);
          }
        }
      } else {
        setRecommendedBand({});
        setCalculatedZoomValues({});
      }
    }
  }, [props.open, props.zoomBand]);

  React.useEffect(() => {
    if (!props.zoomBand) {
      setRecommendedBand({});
      setCalculatedZoomValues({});
    }

    setTargetMatrix([]);

    if ( (selectedTargets || []).length > 0 
        && (((freqType === 'line') && selectedSpectralLine['name']) 
        || ((freqType === 'rest') && (Number(frequency)>0)))
      ) {
          setDisableCalculate(false);
      } else {
        setDisableCalculate(true);
      }
  }, [selectedTargets, frequency, freqType, selectedSpectralLine]);

  const isTargetWithZoom = (target: any) => {
    const skyFrequency = target['sky_frequency'];
    const zoom = getZoom(recommendedBand['zoom']);

    if (zoom && skyFrequency > 0) {
      const bandWidth = zoom['points'] * zoom['spectral_resolution'] / 1000;
      const centreFreq = Number(recommendedBand['centre_frequency']);

      if (bandWidth > 0 && centreFreq > 0) {
        const highFreq = centreFreq + bandWidth /2;
        const lowFreq = centreFreq - bandWidth / 2;

        if (skyFrequency > highFreq || skyFrequency < lowFreq ) {
          return false;
        }
      }
    }

    return true;
  }

  const suggestBand = (skyFrequency: number, restFrequency: number) => {
    const band = Utils.caluculateBand(skyFrequency, 
      props.corrConfig['frequency_configuration']||{});

    if (band && !band['messages']) {
      return {
        id: Date.now(),
        band: band['band'],
        subband: band['subband'],
        centre_frequency: skyFrequency.toFixed(3),
        zoom: '',
        rest_frequency: restFrequency
      };
    }

    return band;
  }

  const calculateZoomValues = (zoomBand: any) => {
    let centerFreq = 0;
    if (zoomBand && zoomBand['centre_frequency']) {
      centerFreq = Number(zoomBand['centre_frequency']);
    }
    if (!zoomBand['zoom'] || centerFreq <= 0) {
      setCalculatedZoomValues({});
      return;
    }

    const zoomName = zoomBand['zoom'];
    const zoom = Utils.getZoomBand(
      projectContext.correlatorSettings,
      props.corrConfig['correlator_setting'], zoomName);
  
    const velocityResolution = ATCAConstants.SPEED_OF_LIGHT * zoom['spectral_resolution']
      / (1000 * centerFreq);

    let x = Math.floor(velocityResolution);
    let d = velocityResolution - x;
    const vResolution = x + Number(d.toPrecision(3));

    const velocityWidth = velocityResolution  * zoom['points'];
    x = Math.floor(velocityWidth);
    d = velocityWidth - x;
    const vWidth = x + Number(d.toPrecision(3));
    
    const calculatedValues = {
      'velocityWidth': vWidth,
      'velocityResolution': vResolution.toFixed(3),
      'dataRate': zoom['data_rate'],
      'points': zoom['points'],
      'spectralResolution': zoom['spectral_resolution'],
      'unit': zoom['unit']
    }

    setCalculatedZoomValues(calculatedValues);
    fetchTargetVelocities(zoomBand, targetMatrix);
  }

  const fetchTargetVelocities = (zoomBand: any, theTargetMatrix: any) => {
    const targets = [];
    const zoom = getZoom(zoomBand['zoom']);

    if (!theTargetMatrix || theTargetMatrix.length==0)
      return;

    if (!zoom || !zoomBand || zoomBand['centre_frequency'] <= 0)
      return;

    const bandWidth = zoom['points'] * zoom['spectral_resolution'] / 1000;
    const centreFreq = Number(zoomBand['centre_frequency']);
    const rest_frequency = Number(freqType === 'line' ? selectedSpectralLine['freq'] : frequency);

    for (const target of selectedTargets) {
      const date = new Date((props.corrConfig||{})['utcDate']);
      const data = {
        "name": target['name'],
        "ra": target['ra'],
        "dec": target['dec'],
        "coord_sys": target['coord_sys'],
        "utc_date": Utils.getDateString(date),
        "rest_frequency": rest_frequency,
        "sky_frequencies": [centreFreq - bandWidth / 2, centreFreq + bandWidth / 2]
      };
      targets.push(data);
    }

    const postOperation = post(
      {
        apiName: 'cabb',
        path: '/velo_velocities',
        options: {
          body: targets,
          headers: {'content-type': 'application/json'}
        }
      }
    );

    postOperation.response
      .then(response => response.body.json())
      .then((response: any) => {
      if (response['status'] === 'success') {
          const results: any[] = response['result'];
          const matrix: any[] = JSON.parse(JSON.stringify(theTargetMatrix));
          for (const target of matrix) {
            const result: any[] = results.filter(t => t['target_name'] === target['name']);
            if (result.length > 0 && result[0]['velocities'] 
              && result[0]['velocities'].length === 2) {

              const velocities = result[0]['velocities'];
              target['zoom_vel_low'] = velocities[0].toFixed(3);
              target['zoom_vel_high'] = velocities[1].toFixed(3);
            }
          }
          setTargetMatrix(matrix);
      } else {
        const message = response['message'];
        if (message)
          setSnackInfo({
            open: true,
            message: message,
            severity: 'error'
          });
      }
    })
    .catch((err: Error) => {
      setSnackInfo({
        open: true,
        message: 'Could not caculate sky frequency: ' + err.message,
        severity: 'error'
      });
    });

  }

  const setZoom = (event: SelectChangeEvent) => {
    const newZoomBand = {...recommendedBand};
    newZoomBand['zoom'] = event.target.value as string;
    setRecommendedBand(newZoomBand);
    calculateZoomValues(newZoomBand);
  }

  const setCentreFreq = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    // change band/subband if neccessary
    const zoomBand: any = suggestBand(Number(event.target.value), recommendedBand['rest_requency']);
    if (zoomBand && !zoomBand['messages']) {
      zoomBand['centre_frequency'] = event.target.value;
      zoomBand['zoom'] = recommendedBand['zoom'];
      zoomBand['id'] = recommendedBand['id'];
      setRecommendedBand(zoomBand);
      calculateZoomValues(zoomBand);
    } else {
      const newZoomBand = { ...recommendedBand };
      newZoomBand['centre_frequency'] = event.target.value;
      setRecommendedBand(newZoomBand);
      calculateZoomValues(newZoomBand);
    }
  }

  const changeSelectedTargets = (event: SelectChangeEvent<any[]>) => {
    setSelectedTargets(event.target.value as any[]);
  }

  const changeSelectedSpectralLine = (event: SelectChangeEvent) => {
    setSelectedSpectralLine(event.target.value);
  }

  const handleCalculate = () => {
    setTargetMatrix([]);

    const targets = [];
    const rest_frequency = Number(freqType === 'line' ? selectedSpectralLine['freq'] : frequency);

    for (const target of selectedTargets ) {
      const date = new Date((props.corrConfig||{})['utcDate']);
      const data = {
        "name": target['name'],
        "ra": target['ra'],
        "dec": target['dec'],
        "coord_sys": target['coord_sys'],
        "utc_date": Utils.getDateString(date),
        "velocity": Utils.getTargetVelocityString(target),
        "rest_frequency": rest_frequency
      };
      targets.push(data);
    }
    
    const postOperation = post(
      {
        apiName: 'cabb',
        path: '/velo',
        options: {
          body: targets,
          headers: {'content-type': 'application/json'}
        }
      }
    );

    postOperation.response
      .then(response => response.body.json())
      .then((response: any) => {
        if (response['status'] === 'success') {
          const results: any[] = response['result'];
          const matrix: any[] = [];
          const frequencies: number[] = [];
          for (const target of selectedTargets) {
            const freq: any[] = results.filter(t => t['target_name'] === target['name']);
            let skyFreq = 0;
            if (freq.length > 0) {
              skyFreq = Number(freq[0]['sky_frequency'].toFixed(3));
            }
            const row = {
              'name': target['name'],
              'velocity': target['velocity'],
              'sky_frequency': skyFreq,
              'zoom_vel_low': 0,
              'zoom_vel_high': 0,
            }
            matrix.push(row);
            frequencies.push(skyFreq);
          }
          setTargetMatrix(matrix);
          if (frequencies.length > 0 && !props.zoomBand) {
            const sum = frequencies.reduce((a, b) => a + b, 0);
            const avg = (sum / frequencies.length) || 0;
            const zoomBand = suggestBand(avg, rest_frequency);
            if (zoomBand) {
              setRecommendedBand(zoomBand);
            }
          } else if (props.zoomBand) {
            fetchTargetVelocities(props.zoomBand, matrix);
          }
        } else {
          const message = response['message'];
          if (message)
            setSnackInfo({
              open: true,
              message: message,
              severity: 'error'
            });
        }
      })
      .catch((err: Error) => {
        setSnackInfo({
          open: true,
          message: 'Could not calculate sky frequency: ' + err.message,
          severity: 'error'
        });
      });
  }

  const containsTarget = (target: any) => {
    const targets = (selectedTargets || []).filter(t => t['name'] === target['name']);

    return targets.length > 0;
  }

  const renderZoomValue = (value: string) => {
    const zoom = getZoom(value);
    if (zoom) {
      return (
        <Stack direction="row" spacing={2} alignItems={'center'}>
          <Typography variant="body1">
            {zoom['name'] || ''}
          </Typography>
          <Typography variant="body2">
            {(zoom['points'] * zoom['spectral_resolution']).toFixed(3)} kHz
          </Typography>
          <Typography variant="body2">
            {zoom['data_rate']} kB/s
          </Typography>
        </Stack>
      );
    } else {
      return (
        <Typography variant="body1"></Typography>
      );    
    }
  }

  const renderTargetValues = (value: any[]) => {
    return (
      <Box sx={{ display: 'flex', overflowY: 'scroll',
      flexWrap: 'wrap', gap: 0.5, maxHeight: '70px' }}>
        {
          value.map((t: any) => (
            <Chip key={t['name']} label={t['name']} />
            )
          )
        }
      </Box>
    );
  };

  const renderSpectralLineValue = (value: any) => {
    return (
      <Stack padding={'10px 10px 0 10px'}>
        <Typography variant="body1">
          {value['name']}
        </Typography>
        <Typography variant="body2" >
          {value['freq']} MHz
        </Typography>
      </Stack>
    );
  }

  const updateZoomBand = () => {
    if (recommendedBand['zoom']) {
      if (!recommendedBand['rest_frequency']) {
        const rest_frequency = freqType === 'line' ? selectedSpectralLine['freq'] : frequency;
        recommendedBand['rest_frequency'] = rest_frequency;
      }
      props.updateZoomBand(recommendedBand);
    }
  }

  const getSubBandInfo = () => {
    const freqSetting = props.corrConfig['frequency_configuration'];
    if (freqSetting) {
      const band = recommendedBand['band'];
      const subband = recommendedBand['subband'];
      const startFreq = Utils.getStartFreq(freqSetting, band, subband);
      const endFreq = Utils.getEndFreq(freqSetting, band, subband);
      return startFreq + ' - ' + endFreq
    }

    return '';
  }

  const getZooms = () => {
    return Utils.getZoomConfigurations(
      projectContext.correlatorSettings,
      props.corrConfig['correlator_setting']);
  }

  const getZoom = (name: string) => {
    const zooms: any[] = Utils.getZoomConfigurations(
      projectContext.correlatorSettings,
      props.corrConfig['correlator_setting']);
    if (zooms && zooms.length>0) {
      const results = zooms.filter(z => z['name'] === name);
      if (results && results.length>0)
        return results[0];
    }

    return null;
  }

  const getStatusRow = (status: any) => (
    <TableRow>
      <TableCell align="center" colSpan={9} 
        sx={{ height: '25px', padding: 0, color: status['color'] }}>
        {status['status'] === 'valid' ? '' : status['status'] + ': ' + status['message']}
      </TableCell>
    </TableRow>
  )

  const targetValueTable = (
    <TableContainer component={Paper} sx={{width: '100%', maxHeight: '215px'}}>
      <Table aria-label="simple table">
        <TableHead sx={{ backgroundColor: '#cfd8dc'}}>
          <TableRow>
            <TableCell width={'20%'} align="center">Target Name</TableCell>
            <TableCell width={'20%'} align="center">Target Velocity</TableCell>
            <TableCell width={'20%'} align="center">Sky Frequency (MHz)</TableCell>
            <TableCell width={'20%'} align="center">Zoom Vel low (km/s)</TableCell>
            <TableCell width={'20%'} align="center">Zoom Vel high (km/s)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {targetMatrix.map((target) => (
            <StyledTableRow key={target['name']}
              sx={{ backgroundColor: isTargetWithZoom(target) ? 'inherits' : '#ffcdd2 !important'}}>
              <StyledTextTableCell align="center">
                {target['name']}
              </StyledTextTableCell>
              <StyledTextTableCell align="center">
                {target['velocity']}
              </StyledTextTableCell>
              <StyledTextTableCell align="center">
                {target['sky_frequency']}
              </StyledTextTableCell>
              <StyledTextTableCell align="center">
                {target['zoom_vel_low']}
              </StyledTextTableCell>
              <StyledTextTableCell align="center">
                {target['zoom_vel_high']}
              </StyledTextTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const subBandTable = (
    <React.Fragment>
      <TableContainer component={Paper} sx={{
        width: '100%'
      }}>
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#cfd8dc', padding: '0' }}>
            <TableRow>
              <TableCell padding={'none'} width={'5%'} align="center">Band</TableCell>
              <TableCell padding={'none'} width={'5%'} align="center">Sub Band</TableCell>
              <TableCell padding={'none'} width={'12%'} align="center">Frequency Range (MHz)</TableCell>
              <TableCell padding={'none'} width={'30%'} sx={{ minWidth: '175px' }} align="center">Zoom Config</TableCell>
              <TableCell padding={'none'} width={'13%'} align="center">Center Frequency (MHz)</TableCell>
              <TableCell padding={'none'} width={'10%'} align="center">Velocity Width (km/s)</TableCell>
              <TableCell padding={'none'} width={'10%'} align="center">Velocity Resolution</TableCell>
              <TableCell padding={'none'} width={'5%'} align="center"></TableCell>
            </TableRow>
          </TableHead>
          {
            recommendedBand['id'] &&
            <TableBody>
              <TableRow>
                <StyledTableCell align="center">
                  {recommendedBand['band']}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {recommendedBand['subband']}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {getSubBandInfo()}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="zoom-selection">Zoom band</InputLabel>
                    <Select
                      labelId="zoom-selection-label"
                      id="zoom-selection-value"
                      label="Zoom band"
                      displayEmpty={true}
                      renderValue={renderZoomValue}
                      value={recommendedBand['zoom'] || ''}
                      onChange={setZoom}
                    >
                      {getZooms().map((config: any) => (
                        <MenuItem key={config['name']} value={config['name']}
                          disabled={
                            Utils.zoomExists(
                              props.corrConfig['sub_band_configuration'],
                              recommendedBand['band'], 
                              recommendedBand['subband'], 
                              config['name'])}
                        >
                          <ListItemText primary={config['name']}
                            secondary={
                              <Stack direction="row" spacing={2}>
                                <Typography variant="body2">
                                  {config['points']} points
                                </Typography>
                                <Typography variant="body2">
                                  {config['spectral_resolution'].toFixed(3)} {config['unit']}
                                </Typography>
                                <Typography variant="body2">
                                  {(config['points'] * config['spectral_resolution']).toFixed(3)} kHz
                                </Typography>
                                <Typography variant="body2">
                                  {config['data_rate']} kB/s
                                </Typography>
                              </Stack>
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledTableCell>

                <StyledTableCell align="center">
                    <TextField id={`center-freq-{index}`} label="Centre Frequency"
                      variant="standard" sx={{ flexGrow: 1 }}
                      value={recommendedBand.centre_frequency || ''}
                      fullWidth
                      onChange={setCentreFreq}
                      inputProps={{ style: { textAlign: 'right' } }}
                    />
                </StyledTableCell>

                <StyledTableCell align="center">
                    {calculatedZoomValues['velocityWidth']}
                </StyledTableCell>
                <StyledTableCell align="center">
                    {calculatedZoomValues['velocityResolution']}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Button variant="contained" disabled={!recommendedBand['zoom']}
                      onClick={updateZoomBand} sx={{minWidth: '100px'}} size="small">
                    {
                      props.zoomBand
                      ?
                      'Update zoom band'
                      :
                      'Add recommended zoom band'
                    }
                    
                  </Button>
                </StyledTableCell>
              </TableRow>
              {
                getStatusRow(Utils.validateZoomBand(
                  projectContext.correlatorSettings,
                  props.corrConfig,
                  recommendedBand))
              }
            </TableBody>
          }
          {
            recommendedBand['messages'] &&
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} align="center">
                    {recommendedBand['messages'].map((message: string, index: number) => (
                      <Typography key={'message_' + index} sx={{ color: '#ef5350'}}>
                      {message}
                    </Typography>
                  ))}
                </TableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>


    </React.Fragment>
  );

  return (
    <Dialog open={props.open} PaperProps={{ sx: {height: '100%', overflowY: 'auto'} }}
      maxWidth="lg" fullWidth>
      <DialogTitle id="file-browser-dialog-title">
        Frequency calculator
      </DialogTitle>
      <DialogContent sx={{height: '100%'}}>
        <Paper sx={{ height: '100%', width: '100%'}}>
          <Stack spacing={2} padding={'0 20px 20px 20px'} height={'calc(100% - 20px)'}>
            <Stack direction={'row'} spacing={2} height={'100px'}
              alignItems={'flex-end'}>

            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="targets-label">Targets</InputLabel>
              <Select
                labelId="targets-label"
                id="selected_targets"
                label="Targets"
                displayEmpty={true}
                multiple
                value={(selectedTargets || []) as any[]}
                onChange={changeSelectedTargets}
                renderValue={renderTargetValues}
                MenuProps={MenuProps}
              >
                {targets.map((target, index) => (
                  <MenuItem key={target['name'] + ' ' + index} value={target}>
                    <Checkbox checked={containsTarget(target)} />
                    <ListItemText primary={target['name']}
                      secondary={
                        <Typography variant="body2">
                          {target['ra']} {target['dec']} {target['coord_sys']} {Utils.getTargetVelocityString(target)} 
                        </Typography>
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

              {  
                freqType === 'rest' &&          
                <TextField id="frequency" label="Rest Frequency (MHz)"
                  sx={{ minWidth: '275px' }} 
                  variant="outlined"
                  value={frequency || ''}
                  onChange={e => setFrequency(e.target.value)}
                />
              }

              {
                freqType === 'line' &&
                <FormControl sx={{ minWidth: '275px' }} variant="standard">
                  <InputLabel id="spectral-line-label">Spectral Line</InputLabel>
                  <Select
                    labelId="spectral-line-label"
                    id="spectral-line"
                    label="Spectral Line"
                    onChange={changeSelectedSpectralLine}
                    value={selectedSpectralLine || ''}
                    renderValue={renderSpectralLineValue}
                    displayEmpty={true}
                  >
                    {ATCAConstants.SPECTRAL_LINE_FREQUENCIES.map((line: any, index) => (
                      <MenuItem key={`line-${index}`}
                        value={line}>
                        <ListItemText primary={line['name']}
                          secondary={
                            <Typography variant="body2">
                              {line['freq']} MHz
                            </Typography>
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }

              <FormControl sx={{ minWidth: '180px' }}>
                <RadioGroup
                  name="freq-group"
                  value={freqType}
                  onChange={(e) => setFreqType(e.target.value as string)}
                >
                  <FormControlLabel value="line" control={<Radio />} label="Line" />
                  <FormControlLabel value="rest" control={<Radio />} label="Rest frequency" />
                </RadioGroup>
              </FormControl>

              {/* <Typography sx={{flexGrow: 4}}>
                {props.utcDate instanceof Date ? props.utcDate.toISOString() : props.utcDate}
              </Typography> */}
            </Stack>

            <Button disabled={disableCalculate}
              variant="contained" size="small"
             onClick={handleCalculate}>
              Calculate
            </Button>

            <Stack sx={{flexGrow: 4}}>
              {targetMatrix.length > 0 && targetValueTable}
            </Stack>

            {(recommendedBand['id'] || recommendedBand['messages']) && subBandTable}
          </Stack>
        </Paper>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.handleClose}>
          Close
        </Button>
      </DialogActions>

      <Snackbar
        open={snackInfo['open']}
        autoHideDuration={6000}
        onClose={e => setSnackInfo({
          open: false,
          message: '',
          severity: 'info'
        })}
      >
        <Alert severity={snackInfo['severity']}>
          {snackInfo['message']}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
