import * as React from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TableContainer, Paper, Table, TableHead, TableRow, 
        TableBody, IconButton, Box, FormControl, Typography,
        InputLabel, MenuItem, TextField, Backdrop, CircularProgress, Stack, ListItemText } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import SubBandView from './SubBandView';
import ConfirmationDialog from '../util/ConfirmationDialog';
import * as Utils from '../util/Utils'
import { ProjectContext } from './ProjectContext';
import { styled } from '@mui/material/styles';
import VeloDialog from '../components/VeloDialog';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    padding: '10px 10px 0 10px'
  },
}));

export default function SubBandBox(props: {
  corrConfig: any,
  corrSetting: any,
  utcDate: Date,
  setSubBandConfigs: (subBandConfigs: any[], sort: boolean) => void,
}) {
  const projectContext = React.useContext(ProjectContext);

  const [openVeloDialog, setOpenVeloDialog] = React.useState(false);
  const [openReorderBackdrop, setOpenReorderBackdrop] = React.useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);

  const [selectedZoomBandIndex, setSelectedZoomBandIndex] = React.useState(-1);
  const [subBandConfigs, setSubBandConfigs] = 
    React.useState<any[]>(props.corrConfig['sub_band_configuration'] || []);

  React.useEffect(() => {
    setSubBandConfigs(props.corrConfig['sub_band_configuration'] || []);
  }, [props.corrConfig]);

  const closeVeloDialog = () => {
    setSelectedZoomBandIndex(-1);
    setOpenVeloDialog(false);
  }

  const updateZoomBand = (zoomBand: any) => {
    // replace the selected zoom with the given zoomband
    const newConfigs = [...subBandConfigs];
    newConfigs[selectedZoomBandIndex] = zoomBand;
    props.setSubBandConfigs(newConfigs, true);
  }

  const editZoomBand = (index: number) => {
    setSelectedZoomBandIndex(index);
    setOpenVeloDialog(true);
  }

  const hasSubBands = (): boolean => {
    return projectContext.hasSubBands(props.corrConfig);
  }

  const deleteSubBandConfig = (selectedBand: number) => {
    setSelectedZoomBandIndex(selectedBand);
    setOpenConfirmationDialog(true);
  }

  const handleConfirmationNo = () => {
    setSelectedZoomBandIndex(-1);
    setOpenConfirmationDialog(false);
  }

  const handleConfirmationYes = () => {
    const newConfigs = [...subBandConfigs];
    newConfigs.splice(selectedZoomBandIndex, 1);
    props.setSubBandConfigs(newConfigs, true);

    setSelectedZoomBandIndex(-1);
    setOpenConfirmationDialog(false);
  }

  const getZoomFreqConfig = (id: number) => {
    const configs = subBandConfigs.filter((config) => {
      return(config['id'] === id);
    });
    if (configs.length > 0) {
      const config = configs[0];
      return config;
    }
    return {};
  }

  const getZoomBand = (id: number) => {
    const configs = subBandConfigs.filter((config) => {
      return (config['id'] === id);
    });
    if (configs.length > 0) {
      const config = configs[0];
      const zooms = getZooms();

      const bands = zooms.filter((band: any) => {
        return (band['name'] === config['zoom']);
      });

      if (bands.length > 0) {
        return bands[0];
      }
    }
    return {};
  }


  const setZoomConfig = (event: SelectChangeEvent, id: number) => {
    const configs = subBandConfigs.filter((config) => {
      return (config['id'] === id);
    });
    if (configs.length > 0) {
      const config = configs[0];
      config['zoom'] = event.target.value as string;
      props.setSubBandConfigs([...subBandConfigs], false);
    }
  }

  const setCentreFreq = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
    id: number) => {
    const bands = subBandConfigs.filter((zoom) => {
      return (zoom['id'] === id);
    });
    if (bands.length > 0) {
      const zoomBand = bands[0];
      zoomBand['centre_frequency'] = event.target.value;
      const newZoomBand = Utils.caluculateBand(
          zoomBand['centre_frequency'], 
          props.corrConfig['frequency_configuration']
        );
      
      if (newZoomBand && !newZoomBand['messages']) {
        if (newZoomBand['band'] !== zoomBand['band'] 
          || newZoomBand['subband'] !== zoomBand['subband']) {
        
          zoomBand['band'] = newZoomBand['band'];
          zoomBand['subband'] = newZoomBand['subband'];
          // if there is a change in band or subband, sort with delay
          setTimeout(() => {
            setOpenReorderBackdrop(true);
            props.setSubBandConfigs([...subBandConfigs], true);
            setTimeout(() => {
              setOpenReorderBackdrop(false);
            }, 1000);
          }, 3000)
        }
      }

      props.setSubBandConfigs([...subBandConfigs], false);
    }
  }

  const setRestFreq = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number) => {
    const configs = subBandConfigs.filter((config) => {
      return (config['id'] === id);
    });
    if (configs.length > 0) {
      const config = configs[0];
      config['rest_frequency'] = event.target.value;
      props.setSubBandConfigs([...subBandConfigs], false);
    }
  }

  const getZooms = () => {
    return Utils.getZoomConfigurations(
      projectContext.correlatorSettings,
      props.corrConfig['correlator_setting']);
  }

  const getStatusRow = (status: any) => (
    <TableRow>
      <TableCell align="center" colSpan={8} 
        sx={{ height: '25px', padding: 0, color: status['color'] }}>
        {status['status'] === 'valid' ? '' : status['status'] + ': ' + status['message']}
      </TableCell>
    </TableRow>
  )

  const renderZoomValue = (selected: any) => {
    if (!selected) {
      return (
        <Typography variant="body1"></Typography>
      );
    }
    const zoom = getZoomBand(selected['id']);
    if (!zoom) {
      return (
        <Typography variant="body1"></Typography>
      );
    } else {
      return (
        <Stack direction="row" spacing={2} alignItems={'center'}>
          <Typography variant="body1">
            {zoom['name'] || ''}
          </Typography>
          <Typography variant="body2">
            {
              (zoom['points'] && zoom['spectral_resolution']) 
              ? '' + (zoom['points'] * (zoom['spectral_resolution'])).toFixed(3) + 'kHz'
              : ''
            }
          </Typography>
          <Typography variant="body2">
            {
              zoom['data_rate'] 
              ? '' + zoom['data_rate'] + 'kB/s'
              : ''
            } 
          </Typography>
        </Stack>
      );
    }
  }


  const subBandTable = (
    <TableContainer component={Paper} sx={{ 
      width: 'calc(100% - 10px)', height: 'calc(100% - 30px)' }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width={'5%'} align="center">Band</TableCell>
            <TableCell width={'5%'} align="center">Sub Band</TableCell>
            <TableCell width={'20%'} align="center">Rest Frequency (MHz)</TableCell>
            <TableCell width={'40%'} align="center">Zoom Config</TableCell>
            <TableCell width={'20%'} align="center">Center Frequency (MHz)</TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subBandConfigs.map((row: any, index: number) => (
            <React.Fragment key={'zoom_' + index}>
              <TableRow
                key={'row-' + row['band'] + '-' + row['subband'] + '-' + index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell align="center" sx={{color: row.band===0 ? 'red':''}}>
                  {row.band}
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ color: row.subband === 0 ? 'red' : '' }}>
                  {row.subband}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <TextField id={`rest-freq-{index}`} label="Rest Frequency"
                    variant="standard" sx={{ flexGrow: 1 }}
                    value={getZoomFreqConfig(row['id'])['rest_frequency'] || ''}
                    fullWidth
                    onChange={(e) => setRestFreq(e, row['id'])}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="zoom-selection">Zoom band</InputLabel>
                    <Select
                      labelId="zoom-selection-label"
                      id="zoom-selection-value"
                      label="Zoom band"
                      displayEmpty={true}
                      renderValue={renderZoomValue}
                      error={!Boolean(getZoomFreqConfig(row['id'])['zoom'])}
                      value={row}
                      onChange={(e: any) => setZoomConfig(e, row['id'])}
                    >
                      {getZooms().map((config: any, index: number) => (
                        <MenuItem key={config['name'] + '_' + index} value={config['name']} 
                          disabled={
                            Utils.zoomExists(
                              props.corrConfig['sub_band_configuration'],
                              row['band'], 
                              row['subband'], 
                              config['name'])}
                        >
                          <ListItemText primary={config['name']}
                            secondary={
                              <Stack direction="row" spacing={2}>
                                <Typography variant="body2">
                                  {config['points']} points
                                </Typography>
                                <Typography variant="body2">
                                  {config['spectral_resolution'].toFixed(3)} {config['unit']}
                                </Typography>
                                <Typography variant="body2">
                                  {(config['points'] * config['spectral_resolution']).toFixed(3)} kHz
                                </Typography>
                                <Typography variant="body2">
                                  {config['data_rate']} kB/s
                                </Typography>
                              </Stack>
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>  
                </StyledTableCell>

                <StyledTableCell align="center">
                  <TextField id={`center-freq-{index}`} label="Centre Frequency"
                    variant="standard" sx={{ flexGrow: 1 }}
                    value={getZoomFreqConfig(row['id'])['centre_frequency'] || ''}
                    fullWidth
                    error={!Boolean(getZoomFreqConfig(row['id'])['centre_frequency'])}
                    onChange={(e) => setCentreFreq(e, row['id'])}
                  />
                </StyledTableCell>

                <StyledTableCell align="center" padding='none'>
                  <IconButton color='primary'
                    onClick={(e) => editZoomBand(index)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </StyledTableCell>

                <StyledTableCell align="center" padding='none'>
                  <IconButton color='primary'
                    onClick={(e) => deleteSubBandConfig(index)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </StyledTableCell>

              </TableRow>
              {getStatusRow(Utils.validateZoomBand(
                projectContext.correlatorSettings,
                props.corrConfig, row))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <React.Fragment>
      <VeloDialog
        zoomBand={subBandConfigs[selectedZoomBandIndex]}
        corrConfig={props.corrConfig||{}}
        updateZoomBand={updateZoomBand}
        open={openVeloDialog}
        handleClose={closeVeloDialog}
        utcDate={props.utcDate}
      />

      <ConfirmationDialog open={openConfirmationDialog}
        title={'Confirm delete zoom configuration'}
        message={'Do you want to delete zoom configuration?'}
        handleNo={handleConfirmationNo}
        handleYes={handleConfirmationYes}
      />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openReorderBackdrop}
      >
        <Stack>
          <Typography>Reordering zoom bands</Typography>
          <CircularProgress color="inherit" />
        </Stack>
      </Backdrop>

      {hasSubBands() &&
        <React.Fragment>
          <SubBandView
            subBandConfigs={subBandConfigs}
            setSubBandConfigs={props.setSubBandConfigs}
            freqConfig={(props.corrConfig||{})['frequency_configuration']||{}}
          />

          <Box sx={{ marginTop: '10px', padding: '5px', 
            width: '100%', height: 'calc(100vh - 450px)' }}>
            {subBandTable}
          </Box>
        </React.Fragment>
      }
      
    </React.Fragment>
  );

};
