import { Dialog, DialogTitle, Stack, DialogContent, 
  Box, DialogActions, Typography, IconButton } 
  from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import * as React from "react";
import * as Utils from '../util/Utils'
import TargetEditor from "./TargetEditor";
import ConfirmationDialog from "../util/ConfirmationDialog";

export default function EditTargetDialog(
  props: {
    open: boolean,
    targets: string,
    handleSave: (targets: string[]) => void,
    handleCancel: () => void,
  }
) {
  const [targets, setTargets] = React.useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  
  React.useEffect(() => {
    if (props.open) {
      // convert string to targets
      // get rid of non-ascii characters
      const targets = Utils.getTargets(props.targets as string);
      setTargets(targets);
    }
  }, [props.open, props.targets]);

  const updateTarget = (newTarget: any) => {
    const newTargets = [...targets];
    const index = selectedIndex;
    newTargets[index] = newTarget;
    setTargets(newTargets);
  }

  function deleteTarget(): void {
    setShowConfirmation(true);
  }

  function doDeleteTarget(): void {
    const newTargets = [...targets];
    newTargets.splice(selectedIndex, 1);
    setTargets(newTargets);
    setShowConfirmation(false);
  }

  function cancelDeleteTarget(): void {
    setShowConfirmation(false);
  }

  return (
    <>
      <ConfirmationDialog 
        open={showConfirmation}
        title={'Delete target'}
        message={'Do you want to delete the target?'}
        handleNo={cancelDeleteTarget}
        handleYes={doDeleteTarget}
      />

      <Dialog open={props.open} PaperProps={{ sx: {height: '70%'} }}
        maxWidth="md" fullWidth>
        <DialogTitle id="file-browser-dialog-title">
          Edit targets
        </DialogTitle>
        <DialogContent>
          <Stack direction={'row'} height={'100%'} spacing= {4}>
            <Box sx={{ minWidth: '350px',
              height: '100%', width: '50%', 
              border: '1px solid #eeeeee',
            }}>
              <List sx={{
                padding: 0, height: '100%', overflowY: 'scroll'
              }}>
                {targets.map((target: any, index) => (
                  <ListItem key={`target-${index}`}
                    role="listitem"
                    onClick={e => setSelectedIndex(index)}
                    sx={{
                      padding: '10px',
                      borderBottom: '1px solid #eeeeee',
                      bgcolor: (selectedIndex === index) ? '#e0f7fa' : '',
                      "&:hover": {
                        cursor: 'pointer',
                        bgcolor: '#f5f5f5',
                      }
                    }}
                    secondaryAction={
                      (index === selectedIndex) &&
                        <IconButton
                          sx={{ "&:hover": { color: 'red' } }}
                          onClick={deleteTarget} edge="end" aria-label="delete">
                          <DeleteOutlineIcon />
                        </IconButton>
                    }>
                    <ListItemText primary={target['name']}
                      secondary={
                        <Stack>
                          <Typography>
                            {target['ra']}  {target['dec']} {target['coord_sys']}
                          </Typography>
                          <Typography>
                            {Utils.getTargetVelocityString(target)}
                          </Typography>
                        </Stack>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <TargetEditor 
              target={targets[selectedIndex]} 
              setTarget={updateTarget}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={e => props.handleSave(targets)}>
            Update
          </Button>
          <Button onClick={props.handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
