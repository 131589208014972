import * as React from 'react';
import Stack from '@mui/material/Stack';
import { FormHelperText, IconButton, Tooltip, 
  Chip, Typography, DialogContent, Dialog } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import InputIcon from '@mui/icons-material/Input';
import Button from '@mui/material/Button';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { grey } from '@mui/material/colors';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';

import TextField from '@mui/material/TextField';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ConfirmationDialog from '../util/ConfirmationDialog';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import * as Utils from '../util/Utils'
import * as ATCAConstants from '../util/ATCAConstants'

import SubBandBox from './SubBandBox';
import { useLocation } from 'react-router-dom';
import { ProjectContext } from './ProjectContext';
import VeloDialog from '../components/VeloDialog';


enum CONFIRMATION_TYPE {
  SET_CORR_CONFIG,
  DELETE_CORR_SETTING,
}

export default function CorrelatorConfigurationView() {
  const projectContext = React.useContext(ProjectContext);
  const queryParams = new URLSearchParams(useLocation().search);
  const [isDirty, setIsDirty] = React.useState(false);
  const [openVeloDialog, setOpenVeloDialog] = React.useState(false);

  const [selectedCorrSetting, setSelectedCorrSetting] = React.useState(-1);

  const [showReceiverInfo, setShowReceiverInfo] = React.useState<boolean>(false);
  const [confirmation, setConfirmation] = React.useState<any>({
    showConfirmation: false,
    data: '',
    changeType: ''
  });

  // corr configuration is a list of
  // {
  //    name: string
  //    frequency_configuration: string - name,
  //    correlator_setting: string - name,
  //    sub_band_configuration: list of
  //        { band: 1, subband: 6, 
  //          zoom: zoom or spectra window config(eg: zoom 1),  centre_frequency}}
  //        }
  // }

  const BandInfoDialog = (
    <Dialog onClose={ e => setShowReceiverInfo(false) } open={showReceiverInfo}>
      <DialogContent>
        <Typography variant="h5" gutterBottom>
          There are {ATCAConstants.NUMBER_OF_IF} bands of width
          {ATCAConstants.IF_BAND_WIDTH} MHz, both have to be within the same receiver range.
        </Typography>
        <List sx={{ pt: 0 }}>
          {
            ATCAConstants.ReceiverRange.map((receiver) =>(
              <ListItem key={`receiver-${receiver.name}`}>
                <Typography width={'70px'}>
                  {receiver['name']}:
                </Typography>
                <Typography>
                {receiver.start} - {receiver.end} MHz
                </Typography>
              </ListItem>
            ))
          }
      </List>
      </DialogContent>
    </Dialog>
  );

  const openReceiverInfo = () => {
    setShowReceiverInfo(true);
  };

  React.useEffect(() => {
    let interval: any = null;
    if (projectContext.autoSave) {
      interval = setInterval(() => {
        // save changes
        if (isDirty) {
          console.log('CorrelatorConfig - auto saving');
          setIsDirty(false);
          projectContext.saveCorrelatorConfiguration();
        }
      }, 10000);
    } else {
      clearInterval(interval);
      console.log('CorrelatorConfig - clear auto saving');
    }
    return () => clearInterval(interval);
  }, [projectContext, isDirty]);

  const saveCorrelatorSetting = () => {
    setIsDirty(false);
    projectContext.saveCorrelatorConfiguration();
  }

  const setCorrelatorSetting = (config: any[]) => {
    setIsDirty(true);
    projectContext.setCorrelatorConfigurations(config);
  }

  const addZoomBand = (zoomBand: any) => {
    if (zoomBand['zoom']) {
      const corrSetting:any = getSelectedCorrConfig('');
      // make sure it does not exist already
      const exists = Utils.zoomExists(
        corrSetting['sub_band_configuration'],
        zoomBand['band'],
        zoomBand['subband'],
        zoomBand['zoom']);

      if (exists)
        return;

      const newZooms: any[] = [...corrSetting['sub_band_configuration']];
      newZooms.push(zoomBand);
      setSubBandConfigs(newZooms);
    }
  }

  const setSubBandConfigs = (config: any[], sort: boolean = true) => {
    const setting = JSON.stringify(projectContext.correlatorConfigurations);
    const newCorrSettings = JSON.parse(setting);
    const corrSetting = newCorrSettings[selectedCorrSetting];

    // sort subbands
    if (sort) {
      const sortedList = config.sort((a: any, b: any) => {
        if (a['band'] === b['band']) {
          if (a['subband'] === b['subband']) {
            return 0;
          }
          return (a['subband'] < b['subband']) ? -1 : 1;
        }
        return a['band'] < b['band'] ? -1 : 1;
      });
      corrSetting['sub_band_configuration'] = sortedList;
    } else {
        corrSetting['sub_band_configuration'] = JSON.parse(JSON.stringify(config));
    }

    setCorrelatorSetting(newCorrSettings);
  }

  const deleteCorrSetting = () => {
    setConfirmation({
      showConfirmation: true,
      data: selectedCorrSetting,
      changeType: CONFIRMATION_TYPE.DELETE_CORR_SETTING,
      title: 'Confirm delete correlator configuration',
      message: 'Do you want to delete the corrector configuration? You will lose zoom configurations.',
    });
  }

  const setFreqConfigValue = (
    event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, 
    fieldName: string
  ) => {
    const newSettings = [...projectContext.correlatorConfigurations];
    const setting = newSettings[selectedCorrSetting];
    let freqConfig = (setting||{})['frequency_configuration']||{};
    freqConfig[fieldName] = event.target.value;
    const result = validateFreqs(freqConfig);
    freqConfig['receiver'] = result['receiver']||'';

    // update band/sub-band for each zoom
    for (let zoom of (setting['sub_band_configuration']||[])) {
      const result = Utils.caluculateBand(
        zoom['centre_frequency'], 
        freqConfig
      );
      zoom['band'] = (result||{})['band']||0;
      zoom['subband'] = (result||{})['subband']||0;
    }

    setCorrelatorSetting(newSettings);
  }

  const validateFreqs = (freqConfig: any) => {
    const freq1 = Number((freqConfig||{})['centreFreq1'] || '');
    const freq2 = Number((freqConfig||{})['centreFreq2'] || '');

    // both have to be within a single receiver
    let error1 = ''
    let error2 = ''

    if (freq1 <= 0) {
      error1 = 'Enter a centre frequency';
    }

    if (freq2 <= 0) {
      error2 = 'Enter a centre frequency';
    }

    if (error1 && error2) {
      return {
        freq1Message: error1,
        freq2Message: error2,
        receiver: ''
      };
    }

    // find the receiver for to freq1, 
    // make sure it's within the receiver range
    let receiver;
    const freq1_start = freq1 - ATCAConstants.IF_BAND_WIDTH /2;
    const freq1_end = freq1 + ATCAConstants.IF_BAND_WIDTH /2;
    for (const rec of ATCAConstants.ReceiverRange) {
      if (freq1_start >= rec['start'] && freq1_end <= rec['end']) {
        receiver = rec;
        break;
      }
    }

    // use the same receiver
    // make sure it's within the receiver range
    const freq2_start = freq2 - ATCAConstants.IF_BAND_WIDTH / 2;
    const freq2_end = freq2 + ATCAConstants.IF_BAND_WIDTH / 2;
    if (receiver) {
      if (freq2_start >= receiver['start'] && freq2_end <= receiver['end']) {
        // do nothing
      } else {
        if (!error2) {
          error2 = 'Out of range';
        }
      }
    } else {
      if (freq1 > 0) {
        if (!error1) {
          error1 = 'Out of range'
       }
      }
    }

    // check if bands overlapping
    if (!(error1 || error2)) {
      const bandOverlap = Utils.isBandsOverlap(
        freq1, 
        ATCAConstants.IF_BAND_WIDTH, 
        freq2, 
        ATCAConstants.IF_BAND_WIDTH);
      
      if (bandOverlap) {
        error1 = 'Bands overlap'
        error2 = 'Bands overlap'
      }
    }

    // check if bands are too far apart
    if (!(error1 || error2)) {
      if (Math.abs(freq1 - freq2) > ATCAConstants.MAX_BAND_SPARATION) {
        error1 = 'Bands are more than ' + ATCAConstants.MAX_BAND_SPARATION/1000 + ' GHz apart'
        error2 = 'Bands are more than ' + ATCAConstants.MAX_BAND_SPARATION/1000 + ' GHz apart'
      }
    }
    
    // if receiver if 7mm, make sure:
    // centre freq of 8Ghz CABB range is NOT between 38 and 44Ghz
    if (!(error1 || error2)) {
      if (isExcludeCabbCentreFreq(freq1, freq2, ((receiver||{})['name'])||'')) {
        error1 = 'CABB band cannot between ' 
                  + ATCAConstants.CABB_EXCLUDEZONE_7mm[0] / 1000
                  + ', '
                  + ATCAConstants.CABB_EXCLUDEZONE_7mm[1] / 1000
                  + ' GHz';

        error2 = 'CABB band cannot between ' 
                  + ATCAConstants.CABB_EXCLUDEZONE_7mm[0] / 1000
                  + ', '
                  + ATCAConstants.CABB_EXCLUDEZONE_7mm[1] / 1000
                  + ' GHz';
      }
    }

    return {
      freq1Message: error1,
      freq2Message: error2,
      receiver: (receiver||{})['name']
    };
  }

  const isExcludeCabbCentreFreq = (freq1: number, freq2: number, receiver: string) => {
    if (receiver !== '7mm') {
      return false;
    }

    const lower_freq = freq1 < freq2 ? freq1 : freq2;
    const upper_freq = freq1 < freq2 ? freq2 : freq1;

    const upper_band = upper_freq + ATCAConstants.IF_BAND_WIDTH / 2;
    const lower_band = lower_freq - ATCAConstants.IF_BAND_WIDTH / 2;

    const middle = (ATCAConstants.CABB_EXCLUDEZONE_7mm[0] + 
                    ATCAConstants.CABB_EXCLUDEZONE_7mm[1]) / 2;

    let cabb_centre_freq = 0;
    if ((upper_band < (ATCAConstants.CABB_EXCLUDEZONE_7mm[1] + 1000)) 
      && (upper_band > middle)) {
      cabb_centre_freq = lower_band + ATCAConstants.CABB_BAND_WIDTH / 2;

    } else if ((lower_band > (ATCAConstants.CABB_EXCLUDEZONE_7mm[0] - 1000)) 
      && (lower_band > middle)) {
      cabb_centre_freq = upper_band - ATCAConstants.CABB_BAND_WIDTH / 2;
    }

    if (cabb_centre_freq >= ATCAConstants.CABB_EXCLUDEZONE_7mm[0] 
      && cabb_centre_freq <= ATCAConstants.CABB_EXCLUDEZONE_7mm[1]) {
        return true;
      }
    
      return false;
  }

  const handleCorrelatorSettingChange = (event: SelectChangeEvent) => {
    const showConfirmation = (getSelectedCorrConfig('sub_band_configuration').length > 0);

    if (!showConfirmation) {
      setCorrConfigName(event.target.value as string);
    } else {
      setConfirmation({
        showConfirmation: true,
        data: event.target.value as string,
        changeType: CONFIRMATION_TYPE.SET_CORR_CONFIG,
        title: 'Confirm correlator setting change',
        message: 'Do you want to change correlator setting? You will lose all subband configrations.',
      });
    }
  }

  const handleConfirmationNo = () => {
    setConfirmation({showConfirmation: false});
  }

  const handleConfirmationYes = () => {
    const data = confirmation['data'];

    if (confirmation['changeType'] === CONFIRMATION_TYPE.SET_CORR_CONFIG) {
      setCorrConfigName(data);
      setSubBandConfigs([])
    } else if (confirmation['changeType'] === CONFIRMATION_TYPE.DELETE_CORR_SETTING) {
      handleDeleteCorrSetting(data);
    }
    setConfirmation({
      showConfirmation: false,
      data: null,
      changeType: null
    });
  }

  const getUTCDate = () => {
    const utcDate = getSelectedCorrConfig('utcDate');
    if (utcDate && Object.keys(utcDate).length > 0) {
      return dayjs(utcDate as string);
    } else {
      return dayjs(projectContext.utcDate);
    }
  }

  const onChangesUTCDate = (newValue: any) => {
    if (newValue) {
      const newSettings = [...projectContext.correlatorConfigurations];
      const setting = newSettings[selectedCorrSetting];
      const date = newValue.toDate();
      setting['utcDate'] = Utils.getDateString(date);
      setCorrelatorSetting(newSettings);
    }
  }

  const handleDeleteCorrSetting = (selectedSetting: number) => {
    const newConfigs = [...projectContext.correlatorConfigurations];
    newConfigs.splice(selectedSetting, 1);
    setCorrelatorSetting(newConfigs);
    let index = selectedSetting - 1;
    index = index < 0 ? 0: index;
    setSelectedCorrSetting(index);
  }

  const setCorrConfigName = (corrConfigName: string) => {
    const newCorrSettings = [...projectContext.correlatorConfigurations];
    const corrSetting = newCorrSettings[selectedCorrSetting];
    corrSetting['correlator_setting'] = corrConfigName;
    setCorrelatorSetting(newCorrSettings);
  }

  const handleSelectSetting = (index: number) => {
    setSelectedCorrSetting(index);
  };

  const addNewCorrSetting = () => {
    const newSettings = [...projectContext.correlatorConfigurations];
    newSettings.push({
      name: 'untitled',
      correlator_setting: '',
      frequency_configuration: {},
      sub_band_configuration: [],
      utcDate: Utils.getDateString(projectContext.utcDate)
    });

    setCorrelatorSetting(newSettings);
    setSelectedCorrSetting(newSettings.length - 1);
  }

  const closeVeloDialog = () => {
    setOpenVeloDialog(false);
  }

  const getSelectedCorrConfig = (name: string) => {
    if (!name) {
      if (selectedCorrSetting<0)
        return {};
      else if (selectedCorrSetting < 0)
        return {};
      else
        return projectContext.correlatorConfigurations[selectedCorrSetting];
    }
    if (selectedCorrSetting < 0) {
      if (name === 'sub_band_configuration')
        return [];

      return {};
    }

    return projectContext.correlatorConfigurations[selectedCorrSetting][name];
  }

  const setCorrSettingName = 
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newSettings = [...projectContext.correlatorConfigurations];
    const setting = newSettings[selectedCorrSetting];
    setting['name'] = event.target.value;

    setCorrelatorSetting(newSettings);
  }

  const hasSubBands = (): boolean => {
    return projectContext.hasSubBands(getSelectedCorrConfig(''));
  }

  const getCorrSetting = () => {
    const corrConfigName = getSelectedCorrConfig('correlator_setting');
    return projectContext.getCorrelatorSetting(corrConfigName);
  }

  const addNewZoomBand = () => {
    const zoomBand = {
      id: Date.now(),
      band: 0,
      subband: 0,
      centre_frequency: '',
      zoom: '',
      rest_frequency: '',
      data_rate: '-',
    };

    const selectedCorrSetting = getSelectedCorrConfig('');
    const newZooms: any[] = [...selectedCorrSetting['sub_band_configuration']];
    newZooms.unshift(zoomBand);

    setSubBandConfigs(newZooms, false);
  }

  const CustomModeBox = (config: any, freqValidation: any) => (
    <Stack spacing={0}>
      <Stack direction={'row'} spacing={2} 
        sx={{ alignItems: 'baseline'}}>

        <TextField id="band-config-name" label="Name" 
          variant="standard" sx={{ width: '40%' }}
          value={config['name'] || ''}
          onChange={setCorrSettingName}
        />

        <TextField required variant="standard" 
          label="Centre Freq (MHz) - Band 1" sx={{ height: '80px', width: '20%' }}
          id={`centre_freq_1`}
          value={((config['frequency_configuration']||{})['centreFreq1'])||''}
          error={Boolean(freqValidation['freq1Message'])}
          helperText={freqValidation['freq1Message'] }
          onChange={(e) => setFreqConfigValue(e, 'centreFreq1')} />

        <TextField required variant="standard" 
          label="Centre Freq (MHz) - Band 2" 
          sx={{
            height: '80px', width: '20%',
            visibility: (config['frequency_configuration']||{})['receiver'] === '16cm' ? 'hidden' : 'visible'
          }}
          id={`centre_freq_2`}
          value={((config['frequency_configuration']||{})['centreFreq2'])||''}
          error={Boolean(freqValidation['freq2Message'])}
          helperText={freqValidation['freq2Message']}
          onChange={(e) => setFreqConfigValue(e, 'centreFreq2')} />

        <Chip label={'Receiver: ' + (((config['frequency_configuration']||{})['receiver'])||'')} size="medium" 
          key={`freq-receiver`} onClick={openReceiverInfo}
          sx={{color: 'black', backgroundColor: grey[100]}}/>
      </Stack>

      <Stack direction={'row'} justifyContent={'space-between'} 
        alignItems={'center'} spacing={2}>
        <FormControl sx={{ width: '40%' }} variant="standard" 
          error={
            (Object.keys(getCorrSetting()).length === 0) ||
            (getCorrSetting()['status'] === 'obsolete')
          }
        >
          <InputLabel id="cor-config">Correlator setting</InputLabel>
          <Select
            labelId="cor-config-label"
            id="cor-config-select-value"
            label="Correlator setting"
            onChange={handleCorrelatorSettingChange}
            value={getSelectedCorrConfig('correlator_setting')||''}
            renderValue={(selected) => selected}
            displayEmpty={true}
          >
            {projectContext.correlatorSettings.map((config: any) => (
              <MenuItem key={config['name']} value={config['name']}
                disabled={config['status'] === 'obsolete'}
              >
                <ListItemText primary={
                  config['name'] + 
                  (config['status'] === 'obsolete' ? ' (obsolete)' : '')
                }
                  secondary={
                    <Stack width={'100%'} direction="row" spacing={2}>
                      <Typography variant="body2">
                        {config['spectral_points_per_subband']} points  
                      </Typography>
                      <Typography variant="body2">
                        {config['spectral_resolution']}{config['spectral_resolution_unit']}
                      </Typography>
                      <Typography variant="body2">
                        {config['data_rate']}kB/s
                      </Typography>
                    </Stack>
                  }
                />
              </MenuItem>
            ))}
          </Select>
          {
            Object.keys(getCorrSetting()).length === 0
            &&
            <FormHelperText>Unknown correlator configuration</FormHelperText>
          }        
          {
            getCorrSetting()['status'] === 'obsolete'
            &&
            <FormHelperText>Obsolete correlator configuration</FormHelperText>
          }             
        </FormControl>
        {
          hasSubBands() &&
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
              <DatePicker label="UTC date"
                sx={{ width: '30%' }}
                format='YYYY-MM-DD'
                value={getUTCDate()}
                onChange={(e) => onChangesUTCDate(e)}
              />
            </LocalizationProvider>
          
            <Button variant="outlined" 
              disabled={!hasSubBands()}
              onClick={e => addNewZoomBand()}>
              Add Zoom Band
            </Button>

            <Button variant="outlined" 
              disabled={!hasSubBands()}
              onClick={e => setOpenVeloDialog(true)}>
              Open Velo
            </Button>
          </>
        }
      </Stack>

      <SubBandBox
        corrSetting={getCorrSetting()}
        corrConfig={getSelectedCorrConfig('')}
        setSubBandConfigs={setSubBandConfigs}
        utcDate={getSelectedCorrConfig('utcDate')}
      />
    </Stack>
  );

  return (
    <Stack id='sub-band-view' 
      margin={'10px'} height={'calc(100vh - 100px)'}>

      <VeloDialog
        zoomBand={null}
        updateZoomBand={addZoomBand}
        corrConfig={getSelectedCorrConfig('')}
        open={openVeloDialog}
        handleClose={closeVeloDialog}
        utcDate={getSelectedCorrConfig('utcDate')}
      />
      
      {BandInfoDialog}

      <ConfirmationDialog open={confirmation.showConfirmation}
        title={confirmation['title']}
        message={confirmation['message']}
        handleNo={handleConfirmationNo}
        handleYes={handleConfirmationYes}
      />

      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Typography key="project" variant="subtitle1" color="primary">
            {queryParams.get('project')}
          </Typography>

          <Typography key="file" variant="h6" color="primary">
            {'Correlator Configuration'}
          </Typography>
        </Breadcrumbs>

        <Button variant="text" onClick={addNewCorrSetting}
          startIcon={<ControlPointIcon />}>
          Add Setting
        </Button>

        <Stack direction={'row'} alignItems={'center'} width={'250px'}
          spacing={1} justifyContent="flex-end">
          <Tooltip title="Import correlator configuration from local file">
            <IconButton aria-label="import" color='primary'>
              <InputIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Save correlator changes to server">
            <IconButton aria-label="save" color='primary' 
              onClick={saveCorrelatorSetting}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <Stack spacing={2} direction={'row'}
        sx={{
          flexGrow: 4,
          border: 'solid 1px #eeeeee',
          borderRadius: '5px',
          height: 'calc(100% - 80px)',
          overflow: 'hidden'
        }}>
        <Box sx={{ width: '25%', height: '100%', overflow: 'scroll',
                    backgroundColor: '#fafafa'}}>
          <List sx={{ padding: 0}}>
            {projectContext.correlatorConfigurations.map((row, index) => (
              <ListItem
                key={'corr-setting-' + index}
                onClick={e => handleSelectSetting(index)}
                sx={{ 
                  backgroundColor: index === selectedCorrSetting ? '#eeeeee' : 'inherit' 
                }}
                secondaryAction={
                  (index === selectedCorrSetting) &&
                  <React.Fragment>
                    <IconButton
                      sx={{ "&:hover": { color: 'red' } }}
                      onClick={deleteCorrSetting} edge="end" aria-label="delete">
                      <DeleteOutlineIcon />
                    </IconButton>
                    {/* <IconButton disabled={index !== selectedCorrSetting}
                      sx={{ "&:hover": { color: 'red' } }}
                      edge="end" aria-label="copy">
                      <ContentCopyOutlinedIcon />
                    </IconButton> */}
                  </React.Fragment>
                }>
                <ListItemText
                  primary={row['name']}
                  secondary={
                    <Typography variant="body2" 
                      sx={{ color: '#0d47a1'}}>
                      {projectContext.calculateDataRate(row['name'])} kB/s
                    </Typography>
                  } />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box
          sx={{ width: '80%', padding: '15px'}}
          style={{margin: 0, borderLeft: 0}}
        >
          {selectedCorrSetting>=0 && 
            CustomModeBox(getSelectedCorrConfig(''), validateFreqs(getSelectedCorrConfig('frequency_configuration')))}
        </Box>
      </Stack>
    </Stack>
  )
}
