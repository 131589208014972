import { Stack, TextField, FormControl, InputLabel, 
  Select, MenuItem } from '@mui/material';
import * as React from 'react';
import * as ATCAConstants from '../util/ATCAConstants';
import * as Planets from '../astro/Planets';

export default function TargetEditor(
  props: {
    target: any,
    setTarget: (scan: any) => void,
}) {
  const [isPlanet, setIsPlanet] = React.useState(false);

  React.useEffect(() => {
    if (props.target) {
      if (Planets.getPlanetNames().includes(props.target['name'])) {
        setIsPlanet(true);
      } else {
        setIsPlanet(false);
      }
    }
  }, [props.target]);

  const setTargetValue = (fieldName: string, value: any) => {
    // check if a planet
    if (fieldName === 'name') {
      if (Planets.getPlanetNames().includes(value)) {
        props.setTarget({
          name: value,
          coord_sys: '',
          ra: '',
          dec: '',
          velocity: '',
          frame: '',
          convention: ''
        });
        setIsPlanet(true);
        return;
      }
    }
    props.setTarget({
      ...props.target,
      [fieldName]: value
    });
    setIsPlanet(false);
  }

  const getNameHelperText = () => {
    if ((props.target||{})['name']) {
      return ' ';
    }

    if ((props.target||{})['coord_sys'] && 
      (props.target||{})['coord_sys'] !== 'J2000') {
      return 'Please enter a name';
    }

    return 'Default name will be constructed from RA Dec';
  }

  return (
    <Stack spacing={2} width={'100%'}>
      <TextField id="default-duration" label="Name"
        fullWidth
        helperText={getNameHelperText()}
        value={(props.target||{})['name'] || ''}
        onChange={(e) => setTargetValue('name', e.target.value)}
        variant="standard" 
      />

      <FormControl variant="standard" fullWidth disabled={isPlanet}>
        <InputLabel id="scan-coord-sys-label">Coordinate system</InputLabel>
        <Select
          labelId="scan-coord-sys-label"
          id="scan-ecoord-syspoch"
          label="Coordinate system"
          displayEmpty={true}
          value={(props.target||{})['coord_sys'] || 'J2000'}
          onChange={(e) => setTargetValue('coord_sys', e.target.value as string)}
        >
          {ATCAConstants.COORDINATE_SYSTEMS.map((val) => (
            <MenuItem key={val} value={val}>{val}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Stack direction={'row'} spacing={2}>
        <TextField id="default-ra" variant="standard" sx={{ flexGrow: '4' }}
          label={ATCAConstants.COORDINATE_SYSTEM_FIELDS[(props.target||{})['coord_sys']||'J2000']['ra']}
          helperText={ATCAConstants.COORDINATE_SYSTEM_FIELDS[(props.target||{})['coord_sys']||'J2000']['ra_format']}
          onChange={(e) => setTargetValue('ra', e.target.value)}
          value={(props.target||{})['ra'] || ''}
          disabled={isPlanet}
        />

        <TextField id="default-dec" variant="standard" sx={{ flexGrow: '4' }}
          label={ATCAConstants.COORDINATE_SYSTEM_FIELDS[(props.target||{})['coord_sys']||'J2000']['dec']}
          helperText={ATCAConstants.COORDINATE_SYSTEM_FIELDS[(props.target||{})['coord_sys']||'J2000']['dec_format']}
          value={(props.target||{})['dec'] || ''}
          onChange={(e) => setTargetValue('dec', e.target.value)}
          disabled={isPlanet}
        />
      </Stack>

      <Stack direction={'row'} spacing={2}>
        <TextField id="velocity" label="Velocity (km/s or redshift)"
          disabled={isPlanet}
          variant="standard"
          sx={{ flexGrow: '4' }}
          value={(props.target||{})['velocity'] || ''}
          onChange={e => setTargetValue('velocity', e.target.value)}
        />

        <FormControl variant="standard" disabled={isPlanet}
          sx={{ minWidth: '115px' }}>
          <InputLabel id="scan-frame-label">Frame</InputLabel>
          <Select
            labelId="scan-frame-label"
            id="scan-frame"
            label="Frame"
            value={(props.target||{})['frame'] || 'lsr'}
            onChange={(e) => setTargetValue('frame', e.target.value as string)}
          >
            <MenuItem value={'lsr'}>LSR</MenuItem>
            <MenuItem value={'barycentric'}>Barycentric</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="standard" disabled={isPlanet}
          sx={{ minWidth: '115px' }}>
          <InputLabel id="scan-convention-label">Convention</InputLabel>
          <Select
            labelId="scan-convention-label"
            id="scan-convention"
            label="Convention"
            value={(props.target||{})['convention'] || 'radio'}
            onChange={(e) => setTargetValue('convention', e.target.value as string)}
          >
            <MenuItem value={'radio'}>Radio</MenuItem>
            <MenuItem value={'optical'}>Optical</MenuItem>
            <MenuItem value={'z'}>Z</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
}