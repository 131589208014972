import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { useLocation } from 'react-router-dom';
import * as Utils from '../util/Utils'

import { get, post } from 'aws-amplify/api';

import 'dayjs/plugin/utc';

export const ProjectContext = React.createContext({
  project: {},
  correlatorSettings: [] as any[],
  correlatorConfigurations: [] as any[],
  setCorrelatorConfigurations: (config: any[]) => {},
  scheduleFileList: [] as string[],
  targetFile: '',
  setTargetFile: (content: string) => { },
  saveTargetFile: (content: string) => { },
  autoSave: true as boolean,
  setAutoSave: (isAutoSave: boolean) => { },
  utcDate: new Date(),
  setUTCDate: (date: Date) => { },
  saveCorrelatorConfiguration: () => { },
  saveFile: (filename: string, content: any) => { },
  hasSubBands: (corrSetting: any): boolean => { return false },
  getCorrelatorSetting: (name: string): any => { return {} },
  getCorrelatorConfiguration: (name: string): any => { return {} },
  calculateDataRate: (name: string): number => { return 0 },
});

// dayjs.extend(require('dayjs/plugin/utc'));

export default function ProjectContextProvider(props: any) {
  const location = useLocation();

  const [snackMessage, setSnackMessage] = React.useState<any>(null);
  const [autoSave, setAutoSave] = React.useState(true);
  const [project, setProject] = React.useState<any>({});
  const [correlatorConfigurations, setCorrelatorConfigurations] = React.useState<any[]>([]);
  const [scheduleFileList, setScheduleFileList] = React.useState<string[]>([]);
  const [targetFileContent, setTargetFileContent] = React.useState<string>('');
  const [utcDate, setUTCDate] = React.useState<Date>(new Date());
  const [correlatorSettings, setCorrelatorSettings] = React.useState<any[]>([]);

  React.useEffect(() => {
    const getOperation = get(
      {
        apiName: 'cabb',
        path: '/get_correlator_settings',
      }
    );

    getOperation.response
      .then(response => response.body.json())
      .then((response: any) => {        
        if (response['status'] === 'fail') {
          let message = response['message'] || '';
          setSnackMessage({
            severity: 'error',
            message: 'Could not retrieve setting from server: ' + message
          });
        } else {
          setCorrelatorSettings(response);
        }
      })
      .catch((err: Error) => {
        setSnackMessage({
          severity: 'error',
          message: 'Could not retrieve correlator settings from server!'
        });
      });

  }, []);

  React.useEffect(() => {
    // no need to retrieve project if root
    if (location.pathname === '/')
      return;

    // retrieve project from the backend
    const queryParams = new URLSearchParams(location.search);
    const projectName = queryParams.get('project');
    if (!projectName) {
      setSnackMessage({
        severity: 'error',
        message: 'No project provided!'
      });
      return;
    }

    const getOperation = get(
      {
        apiName: 'cabb',
        path: '/retrieve_project',
        options: {
          queryParams: {
            project: projectName
          }
        }          
      }
    );

    getOperation.response
      .then(response => response.body.json())
      .then((response: any) => {
    
        if (response['status'] === 'success') {
          setProject(response['project']);
          setCorrelatorConfigurations(response['correlator_configuration_file']);
          setScheduleFileList(response['schedule_files']);
          setTargetFileContent(response['target_file']);
        } else {
          let message = response['message'] || '';
          setSnackMessage({
            severity: 'error',
            message: 'Could not retrieve project from server: ' + message
          });
        }
      })
      .catch((err: Error) => {
        setSnackMessage({
          severity: 'error',
          message: 'Could not retrieve project from server!'
        });
      });
  }, [location]);

  const saveCorrelatorConfiguration = () => {
    const queryParams = new URLSearchParams(location.search);
    const projectName = queryParams.get('project');
    let filename = project['correlator_configuration_file'];
    filename = projectName + '/' + (filename ? filename : 'correlator_configuration.json');
    saveFile(filename, correlatorConfigurations);
  }

  const handleSetTargetFile = (content: string) => {
    setTargetFileContent(content);
    if (autoSave) {
      handleSaveTargetFile(content);
    }
  }

  const saveTargetFile = (content: string) => {
    handleSaveTargetFile(content);
  }

  const handleSaveTargetFile = (content: string) => {
    const queryParams = new URLSearchParams(location.search);
    const projectName = queryParams.get('project');
    const targetfilename = project['target_file'];
    const filename = projectName + '/' + (targetfilename ? targetfilename : 'targets.csv');
    saveFile(filename, content);
  }

  const saveFile = (filename: string, content: any) => {
    const data = {
      filename: filename,
      content: content
    };
    
    const postOperation = post(
      {
        apiName: 'cabb',
        path: '/save_file',
        options: {
          body: data,
          headers: {'content-type': 'application/json'}
        }
      }
    );

    postOperation.response
      .then(response => response.body.json())
      .then((response: any) => {
  
        if (response['status'] === 'success') {
          setSnackMessage({
            severity: 'success',
            message: filename + ' changes saved!'
          });
        } else {
          const message = response['message'];
          if (message)
            setSnackMessage({
              severity: 'error',
              message: message
              });
        }
      })
      .catch((err: Error) => {
        setSnackMessage({
          severity: 'error',
          message: 'Could not save changes!'
        });
      });
  }
  
  const calculateDataRate = (name: string) => {
    // get data rate of correlator settings
    let dataRate = 0;
    const correlatorConfig = getCorrelatorConfiguration(name) || {};
    const correlatorSetting = getCorrelatorSetting(
      correlatorConfig['correlator_setting']
    );

    if (correlatorSetting) {
      dataRate = correlatorSetting['data_rate'] || 0;
    }

    // add all the zoom band data rates
    for (const zoom of (correlatorConfig['sub_band_configuration'] || [])) {
      const name = zoom['zoom'];
      const band = Utils.getZoomBand(
        correlatorSettings,
        correlatorConfig['correlator_setting'],
        name) || {};

      dataRate += band['data_rate'] || 0;
    }

    return dataRate;
  }

  const getCorrelatorConfiguration = (name: string) => {
    const configs = correlatorConfigurations.filter((config: any) => {
      return config['name'] === name;
    });

    return configs.length === 1 ? configs[0] : {};
  }

  const getCorrelatorSetting = (name: string) => {
    const settings = correlatorSettings.filter((settings: any) => {
      return settings['name'] === name;
    });

    return settings.length === 1 ? settings[0] : {};
  }


  const hasSubBands = (corrSetting: any) => {
    // corrmode is valid
    // corrconfig is valid
    // and corrconfig has zoom or spectral windows
    const frequency_configuration = corrSetting['frequency_configuration'];

    if (Object.keys(frequency_configuration).length < 1)
      return false;

    const corrConfigName = corrSetting['correlator_setting'];
    const corrConfig = getCorrelatorSetting(corrConfigName);
    
    if (Object.keys(corrConfig).length < 1)
      return false;

    return corrConfig.number_of_spectral_windows > 0 ||
      corrConfig.number_of_zoom_windows > 0;
  }


  return (<ProjectContext.Provider value={{
    project: project,
    correlatorSettings: correlatorSettings,
    getCorrelatorSetting: getCorrelatorSetting,
    getCorrelatorConfiguration: getCorrelatorConfiguration,
    calculateDataRate: calculateDataRate,
    correlatorConfigurations: correlatorConfigurations,
    setCorrelatorConfigurations: setCorrelatorConfigurations,
    scheduleFileList: scheduleFileList,
    targetFile: targetFileContent,
    setTargetFile: handleSetTargetFile,
    saveTargetFile: saveTargetFile,
    autoSave: autoSave,
    setAutoSave: setAutoSave,
    utcDate: utcDate,
    setUTCDate: setUTCDate,
    saveCorrelatorConfiguration: saveCorrelatorConfiguration,
    saveFile: saveFile,
    hasSubBands: hasSubBands,
  }}>
    {props.children}

    <Snackbar
      open={Boolean(snackMessage)}
      autoHideDuration={6000}
      onClose={e => setSnackMessage(null)}
    >
      <Alert severity={snackMessage ? snackMessage.severity : 'error' }>
        {snackMessage ? snackMessage.message : ''}
      </Alert>
    </Snackbar>

  </ProjectContext.Provider>);
}
