
import { Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, Snackbar, Alert, IconButton,
  Paper, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow,  
  Link} from "@mui/material";
import * as React from "react";
import { styled } from '@mui/material/styles';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import * as AstroUtil from '../astro/AstroUtil';
import * as Transform from '../astro/Transform';
import * as Planets from '../astro/Planets';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CALIBRATOR_SEARCH_URL = 'https://www.narrabri.atnf.csiro.au/cgi-bin/Calibrators/new/caldb_v3.pl';
const CALIBRATOR_URL = 'https://www.narrabri.atnf.csiro.au/calibrators/calibrator_database_viewcal.html';
const RADIUS = 20;

export default function CalibratorDialog(
  props: {
    open: boolean,
    target: any,
    receiver: string,
    utcDate: Date,
    handleAddCalibrator: (calibrator: any) => void,
    handleClose: () => void,
  }
) {
  const [openSnack, setOpenSnack] = React.useState(false);
  const [calibrators, setCalibrators] = React.useState<any[]>([]);


  const getRaDec = () => {
    const newValue = JSON.parse(JSON.stringify(props.target||{}));
    const name = ((props.target || {})['name'] || '').toLowerCase();
    if (Planets.getPlanetNames().includes(name)) {
      const date = props.utcDate || new Date();
      const planDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));  
      const radec = Planets.planet_position(planDate, name);
      const hour = radec['ra'] * 24 / 360;
      newValue['ra'] = AstroUtil.hmsstring(hour);
      newValue['dec'] = AstroUtil.dmsstring(radec['dec']);
      return newValue;
    }

    const coord = (props.target || {})['coord_sys'] || '';
    if (coord.toLowerCase() !== 'j2000') {
      // convert galactic to ra/dec

      if (coord.toLowerCase() === 'galactic') {
        newValue.l = Number((props.target || {})['ra']);
        newValue.b = Number((props.target || {})['dec']);
      }
      return Transform.coord_convert(newValue);
    }

    return props.target;
  }

  React.useEffect(() => {

    setCalibrators([]);

    if (props.open) {
      const target = getRaDec() || {};
      const params = new URLSearchParams({
        'action': 'search',
        'position': target['ra'] + ',' + target['dec'],
        'radius': '' + RADIUS
      });  
      fetch(`${CALIBRATOR_SEARCH_URL}?${params}`)
      .then(res => res.json())
      .then((res: any) => {
        if ((res||{})['matches']) {
          const calList = res['matches'] || [];
          const sortedList = calList.sort(
            (a: any, b: any) => {
              return a['angular_distance'] - b['angular_distance'];
            }
          );
          setCalibrators(sortedList);
        }
      })
      .catch(error => {
        // could not get calibrator
      });
    }    
  }, [props.open]);

  const getFluxDensity = (fluxDensity: any[], receiver: string): string => {
    const fluxes = fluxDensity.filter(
      (f) => {return f['bandname'] === receiver}
    );

    if (fluxes.length > 0) {
      return fluxes[0]['flux_density'];
    }

    return '';
  }

  const getFluxColor = (receiver: string) => {
    if (receiver === (props.receiver || '')) {
      return '#e1f5fe';
    }

    return '';
  }

  return (
    <Dialog open={props.open} PaperProps={{ sx: {height: '80%'} }}
    maxWidth="md" fullWidth>
      <DialogTitle id="calibrator-dialog-title">
        Calibrators near {(props.target||{})['name']||''}
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper} sx={{ width: '100%'}}>
          <Table aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#cfd8dc'}}>
              <TableRow>
                <TableCell align="center" colSpan={4} sx={{border: '0'}}></TableCell>
                <TableCell align="center" colSpan={5} sx={{border: '0'}}>
                  Flux Density (Jy)
                </TableCell>
                <TableCell align="center" colSpan={1} sx={{border: '0'}}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={'%5'} align="center">Distance</TableCell>
                <TableCell width={'15%'} align="center">Name</TableCell>
                <TableCell width={'20%'} align="center">RA</TableCell>
                <TableCell width={'20%'} align="center">Dec</TableCell>

                <TableCell width={'5%'} align="center">16cm</TableCell>
                <TableCell width={'5%'} align="center">4cm</TableCell>
                <TableCell width={'5%'} align="center">15mm</TableCell>
                <TableCell width={'5%'} align="center">7mm</TableCell>
                <TableCell width={'5%'} align="center">3mm</TableCell>

                <TableCell width={'5%'} align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calibrators.map((cal) => (
                <StyledTableRow key={cal['name']}>
                  <TableCell align="center">
                    {(cal['angular_distance'] * 360).toFixed(3)}&#176;
                  </TableCell>
                  <TableCell align="center">
                    <Link href={`${CALIBRATOR_URL}?source=${cal['name']}`}
                      underline="always" rel="noopener" target="_blank">
                      {cal['name']}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {cal['rightascension'].substring(0, cal['rightascension'].indexOf('.'))}
                  </TableCell>
                  <TableCell align="center">
                    {cal['declination'].substring(0, cal['declination'].indexOf('.'))}
                  </TableCell>

                  <TableCell align="center" sx={{backgroundColor: getFluxColor('16cm')}}>
                    {getFluxDensity(cal['flux_densities'], '16cm')}
                  </TableCell>
                  <TableCell align="center" sx={{backgroundColor: getFluxColor('4cm')}}>
                    {getFluxDensity(cal['flux_densities'], '4cm')}
                  </TableCell>
                  <TableCell align="center" sx={{backgroundColor: getFluxColor('15mm')}}>
                    {getFluxDensity(cal['flux_densities'], '15mm')}
                  </TableCell>
                  <TableCell align="center" sx={{backgroundColor: getFluxColor('7mm')}}>
                    {getFluxDensity(cal['flux_densities'], '7mm')}
                  </TableCell>
                  <TableCell align="center" sx={{backgroundColor: getFluxColor('3mm')}}>
                    {getFluxDensity(cal['flux_densities'], '3mm')}
                  </TableCell>

                  <TableCell align="center">
                    <IconButton onClick={()=> props.handleAddCalibrator(cal)}
                      aria-label="add-calibrator" color="primary">
                      <ControlPointIcon/>
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.handleClose}>
          Close
        </Button>
      </DialogActions>

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={e => setOpenSnack(false)}
      >
        <Alert severity="info">
          Calibrator added!
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
