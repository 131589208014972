import { Box, Stack } from '@mui/system';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CabbAppBar from './components/CabbAppBar';
import NavigationMenu from './components/NavigationMenu';
import Project from './project/Project';
import CorrelatorConfigurationView from './schedule/CorrelatorConfigurationView';
import Schedule from './schedule/Schedule';
import ProjectContextProvider from './schedule/ProjectContext';
import { useLocation } from 'react-router-dom';
import TargetCatalogueView from './schedule/TargetCatalogueView';
import ScheduleContextProvider from './schedule/ScheduleContext';
import { Amplify } from 'aws-amplify';
import { signInWithRedirect, AuthSession, fetchAuthSession} from 'aws-amplify/auth'

import './App.css';
import { Alert } from '@mui/material';

const SERVER_ROOT_URL = process.env.REACT_APP_SERVER_ROOT_URL;
const COGNITO_URL = process.env.REACT_APP_COGNITO_URL;

const protocol = SERVER_ROOT_URL?.includes('https') ? 'https' : 'http';
const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
const WEB_CLIENT_ID = process.env.REACT_APP_WEB_CLIENT_ID;

const NEXUS_PROVIDER_NAME = 'CSIRONexus';


Amplify.configure({
  "API": {
    "REST": {
      "cabb": {
        "endpoint": SERVER_ROOT_URL||''
      }
    }
  },
  Auth: {
    Cognito: {
      userPoolId: USER_POOL_ID||'',
      userPoolClientId: WEB_CLIENT_ID||'',

      loginWith: {
        oauth: {
          domain: COGNITO_URL||'',
          scopes: [
            'aws.cognito.signin.user.admin',
            'email',
            'openid',
          ],
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    }
  }
}, {
  API: {
    REST: {
      headers: async () => {
        const session = await fetchAuthSession();
        return { Authorization: session.tokens?.idToken?.toString()||'' };
      }
    }
  }
});


function App() {
  const location = useLocation();
  const [authorised, setAuthorised] = React.useState(false);
  const [email, setEmail] = React.useState<string>('');

  // check if a user is loggin, redirect to login page if not
  const getLoginUser = () => {
    fetchAuthSession()
      .then((session: AuthSession) => {
        if (session['tokens']) {
          const payload = (session['tokens']['idToken']||{})['payload']||{};
          console.log(`user: ${payload['email']} groups: ${payload['cognito:groups']}`);
          setAuthorised(true);
          setEmail(payload['email']?.toString()||'');
        } else {
          setAuthorised(true);
          setEmail('');
          signIn();
        }
      }).catch((err: any) => {
        console.log('not logged in: ' + err);
        signIn();
      });
  }

  const signIn = () => {
    signInWithRedirect().then(() => {
      getLoginUser();
    }).catch((err: any) => {
      console.log('Could not login', err);
      setAuthorised(false);
      setEmail('');
    });
  }

  React.useEffect(() => {
    getLoginUser();
  }, []);

  return (
    <div className="App">
      <CabbAppBar email={email} authorised={authorised}
        refreshUser={getLoginUser}/>
      {authorised ? (
        <ProjectContextProvider>
          <Stack direction={'row'}>
            {location.pathname !== '/' && <NavigationMenu />}
            <Box sx={{flexGrow: 4}}>
              <Routes>
                <Route path='/' element={
                  <Project />
                } />
                <Route path='/target-catalogue' element={
                  <TargetCatalogueView />
                } />
                <Route path='/schedule-editor' element={
                  <ScheduleContextProvider>
                    <Schedule />
                  </ScheduleContextProvider>
                } />
                <Route path='/correlator-setting' element={
                  <CorrelatorConfigurationView />
                } />
              </Routes>
            </Box>
          </Stack>
        </ProjectContextProvider>
        )
        :
        (
          <Alert severity="error">
            You are not authorised to access this application. Please contact 'email' for help.
          </Alert>
        )
      }
    </div>
  );
}

export default App;
